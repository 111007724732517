import React, { Component } from "react";


class Subscribe extends Component {

	constructor() {
			super();
			this.state = {
				width: window.innerWidth,
			};
		}

		componentWillMount() {
			window.addEventListener('resize', this.handleWindowSizeChange);
		}

		// make sure to remove the listener
		// when the component is not mounted anymore
		componentWillUnmount() {
			window.removeEventListener('resize', this.handleWindowSizeChange);
		}

		handleWindowSizeChange = () => {
			this.setState({ width: window.innerWidth });
		};


render() {

	return (

		<div>

			<div className="chapterBox">
				<div className="oliveBox">
					<h1>
						<span className="amethystText">subscribe</span>
					</h1>
       	</div>
      </div>

      <div className="chapterBody">

				<iframe
					className="form"
					src="https://docs.google.com/forms/d/e/1FAIpQLSclnmwzbk-mhLHNMPH41AfqkXCZDyXxOl-dyimUjfJKtFDECw/viewform?embedded=true"
					width="640"
					height="717"
					frameborder="0"
					marginheight="0"
					marginwidth="0"
					>
					Loading... </iframe>

      </div>

  </div>

		)};
}


export default Subscribe;

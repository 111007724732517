import React, { Component } from "react";
import { Link } from 'react-router-dom';

class Chapter2 extends Component {

	constructor() {
			super();
			this.state = {
				width: window.innerWidth,
			};
		}

		componentWillMount() {
			window.addEventListener('resize', this.handleWindowSizeChange);
		}

		// make sure to remove the listener
		// when the component is not mounted anymore
		componentWillUnmount() {
			window.removeEventListener('resize', this.handleWindowSizeChange);
		}

		handleWindowSizeChange = () => {
			this.setState({ width: window.innerWidth });
		};


render() {

	return (

		<div>

			<div className="chapterBox">
				<div className="mintBox">
					<h1>
						<span className="goldText">chapter 2:</span> her note
					</h1>
				</div>
			</div>

			<div className="chapterBody">

				<div className="pgNum">
					4
				</div>

				<h3>

					<p>
		        <span className="chapFW">I</span>ris began her temping job the
						following morning, &amp; though this was her second summer with the
						company, the usual mundanities were nevertheless reenacted on her
						first day back: introductions to the new relocation department she
						would be assisting, the overview of her expectations &amp;
						responsibilities, &amp; a few hours spent shadowing a senior
						member as he worked on his files at his desk. All of this was
		        less monotonous today, she realized, her thoughts disregarding the
		        stumpy walls of the cubicle, the clicking of the computer mouse, &amp;
		        instead running far away, down the stale hallway &amp; out into the
			    	oil-stained parking lot &amp; then diving beneath the brambly shrubs
			    	at its edge &amp; still going on even after all that.
					</p>

					<p>
		        This day felt lighter, perhaps because she found her thoughts
		        returning to the pleasant memories that characterized the majority of
		        her relationship with Ezra. She was fond of recalling how people had
		        come to meet, of tracing the <span className="lilacText">tendrils of
		        fate</span> back to their twisting roots...
	        </p>

					<p>
		        The leaves were still green when they had met all those years ago in
						early September, but as the weeks went by, growing scarlet &amp;
						ocher patches overtook them &amp; they fell &amp; littered the
						ground – &amp; so too did the teacher assign new lab partners. Iris
						&amp; Ezra no longer interacted as they had before (albeit their
						partnership had been professional, his demeanor polite &amp;
		        restrained, hers naïve &amp; afflicted), but still the sight of him
		        caused her heart to flutter, a <span className="goldText">quivering
						blossom in her chest that the chills of blustery autumn could not
						wilt</span>. Winter arrived, &amp; still she thought of him. The New
						Year encouraged change, a resolution to command her destiny, to
						break free from the ordinary, &amp; she drafted a deliberately
						casual note to him, ending with the painfully pubescent plea:
						“<span className="oliveText">call me sometime</span>?”
					</p>

          <p>
		        Between classes, Iris’s best friend, Lydia, spied him walking towards
		        study hall &amp; called out to him, causing him to turn (his brown hair
		        painstakingly gelled, his brow furrowed), to see the two girls from his
		        chemistry class wrestling, Lydia attempting to pull the piece of paper
		        from Iris’s unyielding hands. Iris, consumed by the anxiety of action,
						was second-guessing this gesture, but Lydia was committed to delivering
						the message, forever a stalwart courier of fate. He watched the tussle,
						books under his arm, until Iris shook Lydia off &amp; ran away with
						her best friend at her heels. When they were no longer in view, he
						shrugged &amp; walked into study hall, taking his seat beside his
						friends. Just as the bell sounded, Lydia exploded into the room,
						victorious, thrusting the note at Ezra, exclaiming “
		        <span className="robinText">for you!</span>” before disappearing again.
        	</p>

					<p>
		        Sometime after, as the frosty waves of winter began to recede, he may
		        have called her (but hadn’t she missed his call &amp; so he had left a
		        voicemail? Didn’t they play phone-tag a few times? The details
		        regrettably blurred together in Iris’s mind, so many years later).
		        Nevertheless, the annual science fair arrived, &amp; the chemistry
		        class was required to present their individual projects at the
		        competition for the local school districts. Amongst all of the students
		        from so many different grades &amp; years &amp; schools, Fate again
		        reached down her fickle hands, pinching the shoulder of Iris’s silk
		        blouse between her fingers &amp; clutching at the starched collar of
		        Ezra’s button-down, &amp; she dropped them in the poster hall at tables
		        that were side-by-side. They could not ignore this <span className="pinkText">
		        conspiracy of the universe</span>, &amp; they spoke shyly with one
		    		another between conversations with the wandering judges, this event
		        propelling them towards their futures, which until now had progressed
		        divergently, but henceforth had become <span className="mintText">
						irrevocably intertwined</span>.
					</p>

					<p>
		        That night he called her &amp; they spoke for an hour, thus beginning a
		        tradition that would persist nearly every night for just short of two
		        years. In late March, as the daffodils reared their heads &amp; the
		        birds were returning once again to the North &amp; everyone
		        <span className="eggplantText"> rubbed their eyes
						of the sleeping-sickness that is the winter malaise</span>, he asked
						her on the telephone if she would be his girlfriend, &amp; she said yes.
	        </p>

					<div className="chapImgContainer">
						<div className="evergreenBox">
							<div className="chapImgBorder">
								<img
									className="chapImg"
									src={process.env.PUBLIC_URL + "../images/chapter2.jpeg"}
									alt="staircase mural" />
							</div>
						</div>
					</div>

					<p>
		        Perhaps a week or so later, several hours after school had ended for
		        the day, Iris &amp; Ezra ambled through the halls until they came to a
		        secluded stairwell, &amp; they sat together on the stairs so that they
		        faced the middle landing between the two floors. Upon this wall, a
		        student had created a surrealist dreamscape of abstracted objects &amp;
		        concepts, but in some way it didn’t matter what had been painted on the
		        cinderblock, because Iris’s heart was pounding in her throat as they
		        leaned towards each other for what would be both of their first kisses,
		        inexperienced but eager. She remembered how quickly their lips touched
		        again after the first, how he had clumsily inserted his tongue into her
		        mouth in a fashion that she would come to teach him to refine, drawing
		        on some <span className="redText">inner knowledge</span> about the
						softness &amp; gentleness &amp; subtleties of kissing.
					</p>

			</h3>

			<div className="centered">
				<Link to="/">
					<img
						className="arrow"
						src={process.env.PUBLIC_URL + "../icons/home_green.gif"}
						alt="home icon"
					/>
				</Link>
			</div>

			<div className="pgNum">
				4
			</div>

		</div>
	</div>

	)};
};

export default Chapter2;

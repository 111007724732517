import React, { Component } from "react";
import { Link } from 'react-router-dom';

class Home extends Component {

    constructor() {
        super();
        this.state = {
          width: window.innerWidth,
        };
      }

      componentWillMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);
      }

      componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
      }

      handleWindowSizeChange = () => {
        this.setState({ width: window.innerWidth });
      };

	render() {
      const { width } = this.state;
      const isMobile = width <= 500;

      if (isMobile) {
        return (
          <div>

            <div className="lineMobile">
                  &nbsp;
            </div>

            <div className="smallFont centered">
              this universe is best visited via a desktop computer
            </div>

            <div className="lineMobile">
    			        &nbsp;
    				</div>

            <div className="mobileRow">

              <div className="homeBox lilacBox">
                <b><Link to="/about" className="evergreenText">
    							about</Link></b>
              </div>

              <div className="homeBox oliveBox">
                <b><Link to="/subscribe" className="amethystText">
    							subscribe</Link></b>
              </div>

            </div>

            <div className="mobileRow">

              <div className="homeBox goldBox">
                <b><Link to="/news" className="redText">
    							news</Link></b>
              </div>

              <div className="homeBox eggplantBox">
                <b><Link to="/contact" className="roseText">
    							contact</Link></b>
              </div>

            </div>

            <div className="lineMobile">
    			        &nbsp;
    				</div>

            <div className="chapBox pinkBox">
              <b><Link to="/chapter1" className="redText">
                  chapter 1:
                  </Link></b> the revival
            </div>

            <div className="chapBox mintBox">
              <b><Link to="/chapter2" className="goldText">
                    chapter 2:
                  </Link></b> her note
            </div>

            <div className="chapBox mauveBox">
              <b><Link to="/chapter3" className="amethystText">
                    chapter 3:
                  </Link></b> at the bottom of the flask
            </div>

            <div className="chapBox amethystBox">
              <b><span className="oliveText">
                    chapter 4:
                  </span></b> &lt;coming soon&gt;
            </div>

            <div className="chapBox robinBox">
              <b><span className="evergreenText">
                    chapter 5:
                  </span></b> &lt;coming soon&gt;
            </div>

            <div className="chapBox eggplantBox">
              <b><span className="roseText">
                    chapter 6:
                  </span></b> &lt;coming soon&gt;
            </div>

            <div className="lineMobile">
    			        &nbsp;
    				</div>

          </div>
            );
          } else {

		return(
			<div className="home">

			<div className="row">

				<div className="gridContainer">
					<div className="lilacBox">
						<div className="gridRectTop">
							<h1><Link to="/about" className="evergreenText">
								about</Link>
							</h1>
							<h2>a midwestern bildungsroman work-in-progress;
							<span className="stylized"><span className="evergreenText"> nsfw</span></span></h2>
						</div>
					</div>
				</div>

				<div className="gridContainer">
					<div className="oliveBox">
						<div className="gridRectTop">
							<h1><Link to="/subscribe" className="amethystText">
								subscribe</Link>
							</h1>
							<h2>receive an <span className="stylized">
							<span className="amethystText"> email alert</span></span> when
							new content is posted</h2>
						</div>
					</div>
				</div>

				<div className="gridContainer">
					<div className="goldBox">
						<div className="gridRectTop">
							<h1><Link to="/news" className="redText">
								news</Link>
							</h1>
							<h2>
                <span className="redText"> aug 4 2021 - </span>
                ch 5 added<br/>

              <span className="redText"> march 28 2021 - </span>
              ch 4 added<br/>

              </h2>
						</div>
					</div>
				</div>

				<div className="gridContainer">
					<div className="roseBox">
						<div className="gridRectTop">
							<h1><Link to="/contact" className="eggplantText">
								contact</Link>
							</h1>
							<h2>email <span className="stylized"><span className="eggplantText"> &amp;
							</span></span> social media
							</h2>
						</div>
					</div>
				</div>

	        </div>


				<div className="line">
			        &nbsp;
				</div>


			<div className="row">

                <div className="gridContainer">
                    <img
                        className="gridPhoto"
                        src={process.env.PUBLIC_URL + "../images/chapter1.jpeg"}
                        alt="stormy sky"
                        />
                </div>

                <div className="gridContainer">
                    <div className="pinkBox">
                        <div className="gridRectHalf">
                        <h1><Link to="/chapter1" className="redText">
                            chapter 1:
                            </Link></h1>

                             <h2>the revival</h2>
                        </div>
                    </div>
                </div>

                <div className="gridContainer">
                    <img
                        className="gridPhoto"
                        src={process.env.PUBLIC_URL + "../images/chapter2.jpeg"}
                        alt="mural on staircase wall"
                        />
                </div>

                <div className="gridContainer">
                    <div className="mintBox">
                        <div className="gridRectHalf">
                            <h1><Link to="/chapter2" className="goldText">
                                    chapter 2:
                                </Link></h1>

                                <h2>her note</h2>
                        </div>
                    </div>
                </div>

            </div>



			<div className="row">

                <div className="gridContainer">
                    <img
                        className="gridPhoto"
                        src={process.env.PUBLIC_URL + "../images/chapter3.jpeg"}
                        alt="streetlights at night downtown"
                        />
                </div>

                <div className="gridContainer">
                    <div className="mauveBox">
                        <div className="gridRectHalf">
                            <h1><Link to="/chapter3" className="amethystText">
                                chapter 3:
                            </Link></h1>

                                <h2>at the bottom of the flask</h2>
                        </div>
                    </div>
                </div>

                <div className="gridContainer">
                    <img
                        className="gridPhoto"
                        src={process.env.PUBLIC_URL + "../images/chapter4.jpeg"}
                        alt="playground on a clear day"
                        />
                </div>

                <div className="gridContainer">
                    <div className="amethystBox">
                        <div className="gridRectHalf">
                            <h1><Link to="/chapter4" className="oliveText">
                                chapter 4:
                            </Link></h1>

                                <h2>the yellow house</h2>
                        </div>
                    </div>
                </div>

            </div>



			<div className="row">

                <div className="gridContainer">
                    <img
                        className="gridPhoto"
                        src={process.env.PUBLIC_URL + "../images/chapter6.JPG"}
                        alt="party cups"
                        />
                </div>

                <div className="gridContainer">
                    <div className="robinBox">
                        <div className="gridRectHalf">
                            <h1><Link to="/chapter5" className="evergreenText">
                                chapter 5:
                            </Link></h1>

                                <h2>saturn's jubilee</h2>
                        </div>
                    </div>
                </div>

                <div className="gridContainer">
                    <img
                        className="gridPhoto"
                        src={process.env.PUBLIC_URL + "../images/chapter5.jpeg"}
                        alt="disheveled bed at dusk"
                        />
                </div>

                <div className="gridContainer">
                    <div className="eggplantBox">
                        <div className="gridRectHalf">
                            <h1><span className="roseText">
                                chapter 6:
                            </span></h1>

                                <h2>&lt;coming soon&gt;</h2>
                        </div>
                    </div>
                </div>

            </div>


		</div>
        );

	}
}
}

export default Home;

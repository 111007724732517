import React, { Component } from "react";

class News extends Component {

	constructor() {
			super();
			this.state = {
				width: window.innerWidth,
			};
		}

		componentWillMount() {
			window.addEventListener('resize', this.handleWindowSizeChange);
		}

		// make sure to remove the listener
		// when the component is not mounted anymore
		componentWillUnmount() {
			window.removeEventListener('resize', this.handleWindowSizeChange);
		}

		handleWindowSizeChange = () => {
			this.setState({ width: window.innerWidth });
		};


render() {

	return (

		<div>

			<div className="chapterBox">
				<div className="goldBox">
					<h1><span className="redText">news</span></h1>
				</div>
			</div>

			<div className="chapterBody">

				<h3>
					this page archives relevant news updates related to the website.

					<ul>
						<li><span className="goldText">august 4 2021 </span>
						- chapter 5 added </li>
						<li><span className="cornflowerText">march 28 2021 </span>
						- chapter 4 added </li>
						<li><span className="eggplantText">feb 2021 </span>
						- website 3.1 published</li>
						<li><span className="pinkText">oct 2020 - feb 2021 </span>
						- website offline due to interference</li>
						<li><span className="mauveText">october 2020 </span>
						- website 3.0 + mobile version published</li>
						<li><span className="oliveText">august 2020 </span>
						- website 2.0 thrown into the trash where it belongs</li>
						<li><span className="robinText">april 2020 </span>
						- website 2.0 published</li>
						<li><span className="mintText">jan - mar 2020 </span>
						- website offline due to glitches</li>
						<li><span className="roseText">may 2019 </span>
						- website 1.0 published</li>
					</ul>

				</h3>

			</div>
		</div>

	)};
}

export default News;

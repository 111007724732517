import React, { Component } from "react";
import { Link } from 'react-router-dom';

class Chapter3 extends Component {

	constructor() {
			super();
			this.state = {
				width: window.innerWidth,
			};
		}

		componentWillMount() {
			window.addEventListener('resize', this.handleWindowSizeChange);
		}

		// make sure to remove the listener
		// when the component is not mounted anymore
		componentWillUnmount() {
			window.removeEventListener('resize', this.handleWindowSizeChange);
		}

		handleWindowSizeChange = () => {
			this.setState({ width: window.innerWidth });
		};


render() {

	return (

		<div>

			<div className="chapterBox">
				<div className="mauveBox">
      		 <h1>
					 	<span className="amethystText">chapter 3: </span>
						at the bottom of the flask
					</h1>
        </div>
			</div>

			<div className="chapterBody">

				<div className="pgNum">
					6
				</div>

				<h3>

					<p>
						“Another drink?” Iris asked her friend hurriedly, &amp; attempted to
						get the bartender’s attention so that she might mask her nervousness
						instead with preoccupation. Why did the sight of August make her feel
						like such an awkward prepubescent? She furrowed her brow, leaning
						deliberately onto the counter, but the bartender was too far to
						see Iris jutting out her neck, waving her arm around as though trying
						to hail a cab, some vehicle to carry her away from having to speak with
						August right now. She hardly even felt buzzed, &amp; she was unnerved
						that she was so afflicted by the presence of the other girl; maybe it
						was August's self-certainty that fostered her uneasiness, or perhaps
						it was her overwhelming air of confidence that caused Iris to feel
						gawky in comparison (she wiggled her wrist &amp; fingers more quickly),
						but it was unsuccessful; August placed a hand on Iris’s shoulder so
						that it rested on her blouse. Iris feigned surprise at seeing her,
						abandoning her frantic hand signals at the bar &amp; instead turning
						to face August, who took a big swig of her beer, carefree as ever.
					</p>

					<p>
						“I probably shouldn’t be drinking,” she began, a
						<span className="pinkText"> secret smile playing on her upturned
						lips</span>.
					</p>

					<p>
						“Why is that?” Iris asked, uncertain as to the other girl’s reasoning.
						Maybe she was driving home later on &amp; felt guilty having a drink
						or two first?
					</p>

					<p>
						“If you can believe it,” August began - but the words that followed were
						consumed in a peal of riotous laughter, or perhaps it was a shriek -
						Iris turned in alarm to seek out the source of this hideous noise,
						maybe it was an inebriated old hag throwing back tequila shots, but it
						had ended as quickly as it began, Iris still searching the crowd in
						vain, August’s words finally bringing her back, “... the realness of
						it,” Iris looking back at her again now, “<span className="goldText">I
						keep wondering if somehow this is all a dream</span>.”
					</p>

					<p>
						Iris knew not how to respond, having missed what seemed to be the crux
		        of the sentence, but August suddenly looked uncomfortable, &amp; Iris’s
		        silence seemed to be exacerbating the situation (should she ask her to
		        repeat herself? Had she not noticed the shrill cackle-scream that had
		        pierced the otherwise undulating hum of voices in the bar?), she
		        parted her lips, readying to say something, but then,
					</p>

					<p>
						“Anyway,” August spat out the word crushing the three syllables
						together, “I wanted to see if you’d come over to mine one of these
						days,” her voice steadying, finding itself once more &amp; slowing.
						“My parents are in Europe, so I have the house to myself. I know it’s
						been years since we’ve been <span className="robinText">close friends</span>,
					 but...”
					</p>

					<p>
						“Sure,” Iris accepted quickly, perhaps too quickly, she thought, so
					  she added, spacing out the sounds now to seem more certain, “I’d like
						that.”
					</p>

					<p>
						There was a glimmer in August’s eyes as she backed away from the bar
						to return to the others, &amp; she called out, “
						<span className="redText">tomorrow?</span>” the question
						hovering in the air, stretching from her mouth towards Iris’s ear,
						<span className="oliveText"> a silken thread</span>, &amp;
						below it, the barstools, the sticky puddle of a spilled seven &amp;
						seven, the skinny red straw, a trail of mud.
					</p>

			</h3>

			<h2 className="centered">
				***
			</h2>

			<h3>

				<p>
					Iris returned home, &amp; upon crawling into bed, her thoughts bobbing
					on a sea of vodka, she tumbled into the dream realm.
				</p>

			</h3>

			<div className="trailer">

				<iframe
					className="video"
					title="chapter 3"
					src="https://www.youtube.com/embed/sa4hKKmJPs4"
					frameborder="0"
					allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
					allowfullscreen="true"
					SameSite="none"
					Secure="true"
				>
				</iframe>

			</div>

			<div className="centered">

				<Link to="/chapter3">
					<img
					className="arrow"
					src={process.env.PUBLIC_URL + "../icons/button_L.gif"}
					alt="left arrow"
					/>
				</Link>

				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

				<Link to="/">
					<img
						className="arrow"
						src={process.env.PUBLIC_URL + "../icons/home_green.gif"}
						alt="home icon"
					/>
				</Link>

				</div>

				<div className="pgNum">
					6
				</div>

			</div>
		</div>

	)};
};

export default Chapter3;

import React, { Component } from 'react';
import { HashRouter as HashRouter, Route, Switch } from 'react-router-dom';

import './App.css';

import Header from './components/Header';
import Home from './components/Home';
import About from './components/About';
import News from './components/News';
import Subscribe from './components/Subscribe';
import Chapter1 from './components/Chapter1/Chapter1';
import Chapter1b from './components/Chapter1/Chapter1b';
import Chapter1c from './components/Chapter1/Chapter1c';
import Chapter2 from './components/Chapter2/Chapter2';
import Chapter3 from './components/Chapter3/Chapter3';
import Chapter3b from './components/Chapter3/Chapter3b';
import Chapter4 from './components/Chapter4/Chapter4';
import Chapter4b from './components/Chapter4/Chapter4b';
import Chapter4c from './components/Chapter4/Chapter4c';
import Chapter5 from './components/Chapter5/Chapter5';
import Chapter5b from './components/Chapter5/Chapter5b';
import Chapter5c from './components/Chapter5/Chapter5c';
import Chapter5d from './components/Chapter5/Chapter5d';

import Contact from './components/Contact';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';

class App extends Component {

  render(){

       return (
         <HashRouter>
          <ScrollToTop />
           <div className="App">
             <Header />
               <Switch>
                 <Route exact path="/" component={Home}/>
                 <Route path="/about" component={About}/>
                 <Route path="/subscribe" component={Subscribe}/>
                 <Route path="/news" component={News}/>
                 <Route path="/contact" component={Contact}/>
                 <Route path="/chapter1" component={Chapter1}/>
                 <Route path="/chapter1b" component={Chapter1b}/>
                 <Route path="/chapter1c" component={Chapter1c}/>
                 <Route path="/chapter2" component={Chapter2}/>
                 <Route path="/chapter3" component={Chapter3}/>
                 <Route path="/chapter3b" component={Chapter3b}/>
                 <Route path="/chapter4" component={Chapter4}/>
                 <Route path="/chapter4b" component={Chapter4b}/>
                 <Route path="/chapter4c" component={Chapter4c}/>
                 <Route path="/chapter5" component={Chapter5}/>
                 <Route path="/chapter5b" component={Chapter5b}/>
                 <Route path="/chapter5c" component={Chapter5c}/>
                 <Route path="/chapter5d" component={Chapter5d}/>
               </Switch>

             <Footer />
           </div>
         </HashRouter>
       )

}
};

export default App;

import React, { Component } from "react";
import { Link } from 'react-router-dom';

import Chapter1Header from './Chapter1Header';

class Chapter1 extends Component {

	constructor() {
			super();
			this.state = {
				width: window.innerWidth,
			};
		}

		componentWillMount() {
			window.addEventListener('resize', this.handleWindowSizeChange);
		}

		// make sure to remove the listener
		// when the component is not mounted anymore
		componentWillUnmount() {
			window.removeEventListener('resize', this.handleWindowSizeChange);
		}

		handleWindowSizeChange = () => {
			this.setState({ width: window.innerWidth });
		};


	render() {

		return (
		<div>
        <Chapter1Header/>

			<div className="chapterBody">

				<div className="trailer">

            <iframe
							className="video"
              title="chapter 1"
              src="https://www.youtube.com/embed/Ba6sgJfGRYk"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen="true"
							SameSite="none"
							Secure="true"
							>
            </iframe>

    				<br />
            <br />

            <div className="centered">

              <Link to="/">
                <img
                  className="arrow"
                  src={process.env.PUBLIC_URL + "../icons/home_green.gif"}
                  alt="home icon"
                />
              </Link>

              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

              <Link to="/chapter1b">
                <img
                  className="arrow"
                  src={process.env.PUBLIC_URL + "../icons/button_R.gif"}
                  alt="right arrow"
                />
              </Link>

					</div>
				</div>

				<div className="pgNum">
					1
				</div>

			</div>
		</div>

		)};
};


export default Chapter1;

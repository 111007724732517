import React, { Component } from "react";
import { Link } from 'react-router-dom';

class Chapter3 extends Component {

	constructor() {
			super();
			this.state = {
				width: window.innerWidth,
			};
		}

		componentWillMount() {
			window.addEventListener('resize', this.handleWindowSizeChange);
		}

		// make sure to remove the listener
		// when the component is not mounted anymore
		componentWillUnmount() {
			window.removeEventListener('resize', this.handleWindowSizeChange);
		}

		handleWindowSizeChange = () => {
			this.setState({ width: window.innerWidth });
		};


render() {

	return (

		<div>

			<div className="chapterBox">
				<div className="mauveBox">
      		    <h1>
								<span className="amethystText">chapter 3: </span>
								at the bottom of the flask
							</h1>
        </div>
			</div>

			<div className="chapterBody">

				<div className="pgNum">
					5
				</div>

				<h3>

					<p>
		      	<span className="chapFW">I</span>ris was needed at the office on a
						day-by-day basis depending on their workload, &amp; so she was only
						called in twice more that week after the first day. Despite the
						relatively facile transition back into the nine-to-five, the arrival
						of the weekend was nevertheless justification to seek out a friend
						&amp; together bar-hop between the town’s few local joints.
						Admittedly, they were more acquaintances than friends, but he offered
						her a ride &amp; drinking alone in public was socially questionable,
						so the two of them ventured downtown, his station wagon
						riding upon the hills, the <span className="eggplantText"> quiet
						gloam of the night soft</span>, pressing in on the roof &amp; the
						car doors.
					</p>

					<div className="chapImgContainer">
						<div className="roseBox">
							<div className="chapImgBorder">
								<img
							     className="chapImg"
							     src={process.env.PUBLIC_URL + "../images/chapter3.jpeg"}
							     alt="streetlights at night downtown" />
							</div>
						</div>
					</div>

					<p>
						The Navyman’s Flask had been founded by an old seaman, a transplant
				    from somewhere on the East Coast (maybe it had been Rhode Island).
				    Everyone thought he might have begun to lose his mind, living in this
				    landlocked stretch of grass &amp; plains, &amp; eventually he must have
				    fled back to the ocean, they thought, because no one ever saw him
				    anymore, &amp; his bartenders were none the wiser. Regardless, the
				    maritime influence remained within The Flask, even in his absence:
				    the rusty anchor mounted on the wall, the black &amp; white photos of
				    sailors, of the sea; the uneven, creaking floor underfoot (walking upon
				    it, one felt as though staggering across a ship’s deck); the barstools
				    painted with letters from the nautical alphabet; the ever-present scent
				    of <span className="mintText">salt &amp; whiskey &amp; hot breath</span>.
					</p>

					<p>
						It was a popular haunt for the town’s older folks, but the end of the
						semester (&amp; likely the draught specials) brought forth a swell of
						twenty-somethings, crashing against the clapboard bar-front like
						snarls of seaweed &amp; bloated, cloudy-eyed fish carcasses left behind
						at low tide. Iris surveyed the room but she could smell their stink
						even before she saw them: the slimy kid who had vandalized the corner
						store with impunity, the field hockey bully who pulled down her
						teammates shorts on the field as though sexual harassment was a
						justifiable practical joke, &amp; the boy who’d graduated with honors
						even though he’d brazenly cheated on every exam, all of these wicked &amp;
						wanton people with whom Iris had attended high school – &amp; suddenly
						she was painfully aware of how sober she was. She grabbed her
						companion’s wrist &amp; dragged him towards the bar, dodging a
						crotchety man near a booth &amp; a swarm of ex-cheerleaders, asking
						him, what did he want to drink? She ordered two screwdrivers &amp;
						downed her own in a few large gulps. He might be pacing himself, but
						she certainly wasn’t, catching the bartender’s eye again to obtain the
						Long Island Iced Tea that better suited her desperate departure from
						the <span className="cornflowerText">harsh lucidity of her sobriety</span>.
					</p>

					<p>
						Iris didn’t have a great deal of experience with bars; at school she
						hardly ever went, because the student body preferred to remain
						sequestered within the campus bubble, attending house parties or dances
						sponsored by the college instead. The club in the basement of Main
						Building had once served alcohol when she had still been underage, but
						some drunken imbecile broke the beer tap when he’d reached across the
						counter &amp; tried to help himself, &amp; he’d ruined it for everyone;
						they removed the bar permanently by the end of the month. This did
						nothing to attenuate their alcohol consumption, however, &amp; instead
						students drank before or snuck sips from water bottles hidden in
						peacoats, &amp; inevitably in the dark, buzzing room, sometimes with
						flashing lights &amp; strobes, <span className="goldText">eyes might
						meet &amp; then limbs happened</span>, a hand reaching, somehow
						kissing, swaying to the pulse that beat through the walls &amp; up
						from the floor. Iris wasn’t entirely certain how it usually occurred,
						who initiated, how such things began, blacking out erased a lot of
						the narrative, as though it never existed, as though pages ripped from
						a book...
					</p>

					<p>
						This was nothing like that.  Admittedly, some of the patrons looked
						quite drunk, but most were closer to sober; there were a few adults,
						probably regulars, she deduced in observing the crowd, &amp; then the
						invasion of college kids back on break. Iris scanned the faces,
						suddenly feeling her heart catch in her throat – across the room,
						shrouded in the darkness (<span className="lilacText">the
						air purple &amp; spicy</span>), Iris spotted August, with three
						males surrounding her. Despite the meager, oily light,
						<span className="roseText"> she glowed</span>, commanding
						their attention but without need for it, perhaps even bored
						by it, the way in which her eyes wandered lazily around the space. Her
						gaze found the other girl, &amp; Iris looked away quickly, suddenly
						feeling foolish, turning instead to face the bar, but acutely aware of
						August's form in her peripheral vision, gently parting the crowd with
						her arms as she approached, a subtle smile on her face.
					</p>

				</h3>

				<br />

				<div className="centered">
					<Link to="/">
						<img
							className="arrow"
							src={process.env.PUBLIC_URL + "../icons/home_green.gif"}
							alt="home button"
						/>
					</Link>

				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

				<Link to="/chapter3b">
					<img
						className="arrow"
						src={process.env.PUBLIC_URL + "../icons/button_R.gif"}
						alt="right arrow"
					/>
				</Link>

				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

				<a
					href="https://afterworldiris.com/redirect.html"
					target="_blank"
					rel="noopener noreferrer"
				><img
					className="arrow"
					src={process.env.PUBLIC_URL + "../icons/button_R.gif"}
					alt="right arrow"
				/></a>
			</div>

			<div className="pgNum">
				5
			</div>

		</div>
	</div>

	)};
};

export default Chapter3;

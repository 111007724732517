import React, { Component } from "react";
import { Link } from 'react-router-dom';

class Chapter5 extends Component {

	constructor() {
			super();
			this.state = {
				width: window.innerWidth,
			};
		}

		componentWillMount() {
			window.addEventListener('resize', this.handleWindowSizeChange);
		}

		// make sure to remove the listener
		// when the component is not mounted anymore
		componentWillUnmount() {
			window.removeEventListener('resize', this.handleWindowSizeChange);
		}

		handleWindowSizeChange = () => {
			this.setState({ width: window.innerWidth });
		};


render() {

	return (

		<div>

			<div className="chapterBox">
				<div className="robinBox">
      		    <h1>
								<span className="evergreenText">chapter 5: </span>
								saturn's jubilee
							</h1>
        </div>
			</div>

			<div className="chapterBody">

				<div className="pgNum">
					10
				</div>

				<h3>

        <p>
					<span className="chapFW">T</span>he days at work tumbled one into the
					next. She updated her voicemail message as was required of her:
					“<i>Hello &amp; thank you for calling our relocation services department.
					You've reached the desk of Iris for Friday, June 17th. I will be in the
					office today until 5 pm. Please leave a detailed message with your name
					&amp; number, &amp; I will return your call within one business hour.
					Thank you &amp; have a great day.</i>” The hours were so painful when
					she was not busy – <span className="mintText">daydreaming was
					divine</span> but while doing this she had to attempt to look occupied
					so that her supervisor didn't see she was without work &amp; send her
					home early with just a half-day's pay. She needed the money to attempt
					to begin paying off the immense debt she had &amp; was still accruing
					from attending a private college.
      	</p>

				<p>
					At the least, today was Friday, &amp; at ten until five she feverishly
					gathered up the papers &amp; folders before her, stacking them in a hasty
					pile on her desk, &amp; stood, swinging her purse over her shoulder &amp;
					turning on her heel towards the stairwell. The buzzing anticipation of the
					weekend (&amp; this weekend in particular, this was the eve of August's
					party), this burgeoning buzz tingled in her ears as she took the stairs
					two at a time, flinging open the door to the parking lot with such careless
					force she nearly flattened the security guard standing on the sidewalk
					outside the exit. He jumped, obviously startled, but cleared his throat &amp;
					called out to her gruffly, “have a nice weekend, miss.”
				</p>

				<p>
					“You too,” she replied, the words <span className="eggplantText">
					effervescent on her tongue</span>, rising from between her lips &amp;
					carrying backwards to his ears over her right shoulder, the bare skin
					there prickling in the stale heat of the parking lot.
				</p>
			</h3>

			<h2>***</h2>

			<h3>

			<p>
				The following night, back in her bedroom, she had fought with the
				contents of her closet as she stood before its mirrored doors,
				furrowing her brow at this blouse &amp; that skirt as the daylight
				outside faded steadily. Finally she settled on a purple strapless dress
				that clung to the curves of her hips &amp; paired it with her black
				cowboy boots. Now she found herself seated at the kitchen table, her
				eyes fixated on the arc of the swinging pendulum of the clock mounted
				on the wall. She didn't expect her ride for at least another half hour,
				&amp; the looming, stretching expanse of time before then made her
				feel suddenly nervous, her heart fluttering faster than the ticking of
				the timepiece. She stood up &amp; ventured to the fridge, deciding that
				she'd attempt to calm her nerves by <span className="pinkText">seeking
				solace at the bottom of a bottle</span>. Finding none after the first,
				she downed a couple more to search there too, just for the sake of being
				thorough. Overhead the light buzzed softly (the house otherwise quiet),
				&amp; in fact, slowly but certainly, Iris realized she felt a bit buzzed,
				too, staring at the brown bottles on the table as she thought about
				weekends at college. She couldn’t let herself go that far tonight. There
				had been times when she had overindulged so incredibly that a gaping
				real-time void formed, <span className="evergreenText"> swallowing up the
				seconds</span> as they passed (there were no memories), but
				suddenly, sputtering, it spit her out, &amp; blinking stupidly, she
				remembered that she existed, &amp; thought that she probably should
				escape, even if it meant without a jacket or shoes in the dead of winter
				&amp; the dark of night. Everywhere there was emptiness, carnal
				desperation, the urge to flee, to hide somewhere alone &amp; isolated
				where only the most basic &amp; essential bodily functions
				(e.g., breathing) could be met in privacy, as all higher-order
				functions were rendered unimportant at this moment in time.
			</p>

			<p>
				After a night of such brain-bleeding binging, she would pass out &amp;
				eventually (impossibly) awake again in the morning, realizing that for
				several hours, she had had <span className="goldText">no knowledge that
				she was even alive</span>. Yet even after rising, the void followed her,
				the temporal tail of her rediscovered self-awareness leaving behind a
				greasy stain in its wake, dragging along the oily creature darkness of
				those hours that were lost forever. Yes, she acknowledged that there
				had been some messy nights, but was this not the reality of the college
				student's existence?
			</p>

			<p>
				Somewhat heeding this recollection, but mostly failing miserably, half
				a bottle of wine (a sugary $3 white zinfandel) was consumed
				absentmindedly during these ruminations, before Iris realized her ride
				would be arriving soon. She rose from her seat, clutching the bottle’s
				neck so that she wouldn’t forget it, &amp; traded the silence of the
				kitchen for the cacophony of crickets on her driveway. She strode to
				the end of her road, the sky gradually reducing to a velvet violet,
				streaks of grey scarring it like charcoal veins. In fact, the whole
				spread was quite reminiscent of a lovely bruise, Iris thought, &amp;
				had it more yellow, it would look like the
				<span className="redText"> petal-shaped contusions</span> that
				wilted on her breasts after that first one-night stand freshman year.
				Iris smirked at the memory.
			</p>

			<p>
				The car was waiting for her &amp; she slid into the passenger seat with
				the ease of someone who has had a few drinks (motor actions seemed so
				much more fluid &amp; fun, she thought, savoring the way in which her
				neck rotated smoothly as she turned from the side mirror reflecting the
				darkening skyline instead to the face of the person operating the
				vehicle). She said hello through upturned lips, initiating small talk;
				they were acquaintances &amp; did not know each other very well, but it
				did not matter because <span className="amethystText"> he was sober &amp;
				she was not</span> and the two of them were headed to the Yellow House.
			</p>

			<p>
				It stood before the dusk sky in all of its immensity, and it might have
				been intimidating, were it not for the vibrations it emitted, the jagged
				but consistent pulsing of music, the jovial voices that bounced about on
				the internal walls before tumbling out of the windows &amp; the front door,
				which stood ajar, <span className="cornflowerText">ushering in all who
				came upon it</span>, the house, this labyrinthine entity of weathered wood
				&amp; electrical guts &amp; maze-tracks of pipe &amp; wire. Iris &amp;
				the acquaintance - what was his name again? The alcohol by now had decreased
				her propensity to care, though it was absurd that he had driven her here but
				she couldn't even recall the name by which he called himself... this
				thought evaporated as they approached the house, drawn like moths to a
				flame; but knowing that August was inside, the approach, (the surrender)
				was appropriate &amp; sweet. The wine buoyed her, a refreshing fog on
				her senses, a gentle lifejacket for her passions, &amp; she was carried
				towards the porch as though a twig down a raging river.
			</p>

			<p>
				The transformation of the foyer, only days before spacious, almost
				uncomfortably large, was jarring; now, there was a keg near the entrance
				to the dining room, &amp; everywhere there was drunken banter, the sway of
				dance or intoxicated stumble, disconnected conversations,
				<span className="mauveText"> the revealing of truths</span>, collective
				reveling in the chaos, the slamming of shots &amp; the
				belligerent urge to gorge or weep or cackle or hump or break something.
				She recognized the faces of high school peers, but there were many people
				she did not know, likely other students home on break, maybe from the
				nearby towns, present to engage in this art of bacchanalian celebration
				they all conscientiously studied during the school year at their separate
				colleges.
			</p>

			<p>
				Iris's cowboy boots likely clinked upon the hardwood floors as she ventured
				into the front parlor, but the sound was lost to the noise. She entered
				the ornate room by herself, the acquaintance with whom she'd arrived
				already consumed by the house, she supposed. Ordinarily she despised small
				talk with strangers because she felt that people rarely said things of
				substance when they’ve only just met, instead clinging desperately to some
				image of themselves they believe to be true, or wish for others to believe
				to be true. Talking to tipsy people was much more fascinating, however,
				because intoxicated individuals were much more likely to share something
				interesting or revealing, a secret perhaps, having risen from the vault in
				their gut up through the throat, slipping easily off the tongue, lubricated
				by their beverage of choice. This was a game Iris enjoyed, &amp; so she
				strode confidently past the girls whispering on the couch, the dude rolling
				a joint on the windowsill, turning her head slowly back &amp; forth so
				that she might survey the room, daring its inhabitants to make eye contact
				with her, to prove themselves worthy of this rare exchange. Shrouded in the
				heterotopia that drinking permitted, a fragile world surfaced from the
				hardwood floors, the cushion of the rose-blossom carpet, lifting itself
				into the air, <span className="oliveText">a strange bubble that encased
				them all</span> &amp; permitted the ever-tenuous social ties to stretch
				more elastically.
			</p>




			</h3>

				<br />

				<div className="centered">
					<Link to="/">
						<img
							className="arrow"
							src={process.env.PUBLIC_URL + "../icons/home_green.gif"}
							alt="home button"
						/>
					</Link>

				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

				<Link to="/chapter5b">
					<img
						className="arrow"
						src={process.env.PUBLIC_URL + "../icons/button_R.gif"}
						alt="right arrow"
					/>
				</Link>

			</div>

			<div className="pgNum">
				10
			</div>

		</div>
	</div>

	)};
};

export default Chapter5;

import React, { Component } from "react";

class Contact extends Component {

	constructor() {
			super();
			this.state = {
				width: window.innerWidth,
			};
		}

		componentWillMount() {
			window.addEventListener('resize', this.handleWindowSizeChange);
		}

		// make sure to remove the listener
		// when the component is not mounted anymore
		componentWillUnmount() {
			window.removeEventListener('resize', this.handleWindowSizeChange);
		}

		handleWindowSizeChange = () => {
			this.setState({ width: window.innerWidth });
		};


render() {

	return (

		<div className="contact">

			<div className="chapterBox">
				<div className="roseBox">
					<h1><span className="eggplantText">contact</span></h1>
				</div>
			</div>

			<div className="chapterBody">

				<h3>

					<ul>
						<li><span className="oliveText">email </span>
						- underworld.iris@gmail.com</li>
						<li><span className="lilacText">twitter </span>
						- @underworldiris</li>
					</ul>

				</h3>

				<div className="chapImgContainer">
					<div className="robinBox">
						<div className="chapImgBorder">
							<img
									className="chapImg"
									src={process.env.PUBLIC_URL + "../images/stairs.jpeg"}
									alt="" />
						</div>
					</div>
				</div>

			</div>


		</div>

	)};
}


export default Contact;

import React, { Component } from "react";
import { Link } from 'react-router-dom';

class Chapter4c extends Component {

	constructor() {
			super();
			this.state = {
				width: window.innerWidth,
			};
		}

		componentWillMount() {
			window.addEventListener('resize', this.handleWindowSizeChange);
		}

		// make sure to remove the listener
		// when the component is not mounted anymore
		componentWillUnmount() {
			window.removeEventListener('resize', this.handleWindowSizeChange);
		}

		handleWindowSizeChange = () => {
			this.setState({ width: window.innerWidth });
		};


render() {

	return (

		<div>

			<div className="chapterBox">
				<div className="amethystBox">
      		 <h1>
					 	<span className="oliveText">chapter 4: </span>
						the yellow house
					</h1>
        </div>
			</div>

			<div className="chapterBody">

				<div className="pgNum">
					9
				</div>

				<h3>

          <p>
            “I still remember your 11th birthday party,” Iris interjected,
            catching August in the middle of a treatise on the merits of drinking
            with lacrosse players as opposed to basketball players.
          </p>

          <p>
            “<span className="lilacText">Eleven</span>? Which party was that?”
            she asked, &amp; Iris chanted the highlights: pedicures &amp;
            manicures at the salon; pizza at the playground; cake at her aunt's
            house downtown, across from the park. This was the best attempt Iris
            could manage at reconstructing that day for the other girl, the
            phenomenology of those hours <span className="oliveText"> lost
            between the words</span>, the ineffable watercolor glow, the glimmer,
            the retiring light at the height of sundown caught between the
            strands of August's magenta-tipped hair, her head thrown back in a
            laugh, frozen there on the swing set, the summer air tangy &amp;
            thick but this moment, frozen in time; earlier the bubbles of the
            foot-bath singing to Iris's toes &amp; her eyes closed, thinking the
            thought, “perhaps this is what it feels like to grow up, to
            <span className="goldText"> step slightly closer to the future</span>,
            to adulthood, womanhood”; &amp; at last, August's inflated cheeks
            illuminated by the birthday candles on her cake, the air she held in
            her mouth, (in her mind she made wishes; what did eleven-year old
            girls wish for, Iris longed to recall? Perhaps for plentiful friends
            &amp; popularity; for a crush to return a smile in homeroom; for
            training bras to become the lacy ones with padding; for fewer chores,
            &amp; more autonomy), &amp; all throughout the room there were
            shadows, Iris still saw, a decade later the images coming back as
            fragments, <span className="pinkText">individual
            paintings that bleed together until it's all dripping paint on canvas</span>,
            nothing but shadows, had there even been others present? - the candles
            eternally lit, never to be extinguished, August before them,
            wishing, <span className="evergreenText">making wishes
            for infinity</span>, &amp; the cake, its frosting sea-foam green,
            canary yellow, &amp; in cursive, the word <i>Eleven</i>.
          </p>


          <div className="chapImgContainer">
              <div className="amethystBox">
              <div className="chapImgBorder">
                <img
                  className="chapImg"
                  src={process.env.PUBLIC_URL + "../images/chapter4.jpeg"}
                  alt="swingset on a clear day" />
              </div>
            </div>
          </div>

          <p>
            “I recall just pieces of it,” August replied, “but I think I remember
            it being fun.” She reached for the phone, its curling cord tenuously
            stretching from its port in the far wall, across the braided floor
            mat &amp; over to the large bed dressed in green. “Let's start making
            calls,” she announced, taking the phone from its cradle as Iris tilted
            back her glass &amp; drained it. Iris could feel the liquid calming
            her nerves, &amp; she giggled when August called a friend &amp; spoke
            into the phone, “Hello, it is Madam Augustus,” her voice low &amp;
            throaty, &amp; oh what a <span className="eggplantText">magical
            beverage</span> this was, her nervousness transformed instead into
            giddiness, this bubbling effervescent life-force of feeling &amp;
            sensation, here she was in this room with fluttering chrysalis beetle's
            wings curtains &amp; the cornsilk wallpaper &amp; the florid
            grass-tangle bedspread &amp; there was August, the plastic crescent
            against her cheek, into which she whispered
            <span className="roseText"> mythos from her plum-stained lips</span>,
            she was captivating, &amp; she glowed....
          </p>

        </h3>

        <h2>
            ***
        </h2>

        <h3>

          <p>
            Iris departed the Yellow House, stepping carefully down the front
            stairs so that she did not stumble from the wine that wished her to
            wobble, lost in her thoughts, as usual, &amp; it was as though an
            eye was peering at her from the sky, focused on the top of her
            head, where her red hair simmered in the sun, &amp; then the eye
            zoomed out a bit, seeing too the way Iris was seating herself on the
            bike, releasing the kickstand &amp; setting free a small maelstrom of
            gravel &amp; dust, &amp; soon Iris became a speck in the gray streak
            that was the driveway carving its way through the expanses of field,
            the corn, the cows, the lakes, &amp; still, there were the other
            neighboring farms, the paved routeway that granted Iris access back
            to her home, &amp; <span className="cornflowerText">then the eye could
            see it all</span>, Iris’s home near the farmhouses in the north &amp;
            then downtown, the clutter of buildings &amp; residences (there was
            Ezra’s house) &amp; then over near the southeast hills there was an
            orchard, &amp; Iris’s childhood home, cradled by the crooked trees
            that dotted the land.
          </p>

        </h3>

        <h2>
            ***
        </h2>

        <h3>

        <p>
          Seated beside her open window, so that the sweat clinging to her
          neck &amp; shoulders might be tamed by the occasional blessèd breeze,
          Iris felt compelled to locate a pen, &amp; in the sky the
          late-afternoon sun burned with orange fury. Her gait was still a bit
          clumsy from the wine she’d shared with August, but her fingers found a
          ballpoint &amp; the smooth cover of her journal, &amp; she returned to
          the bed, flicking through the pages until she <span className="mauveText">
          ceased to see old words</span>. She recorded the details of the day
          thus far in standard fashion: the lingering strands of the dream from
          which she had awoken; the way in which the countryside felt in this
          season; the circumstances leading to her journey to the Yellow House;
          &amp; then, like a <span className="lilacText">drunken hiccup</span>,
          the illusion of maintaining this routine burst, &amp; she saw herself
          scrawling something perhaps venturing towards the forbidden,
          <i> What did it feel like...</i> &amp; paused for a moment, &amp;
          then she was suddenly outside of herself again &amp; the words were
          appearing on the page:
        </p>

        <div class="diaryBox1">
          <div class="diaryBox2">
            <div class="diaryScrollBox">
              <div class="diaryBox3">
                <div class="diary">

            What did it feel like to kiss a girl? I wanted to know. I had one
            hand on the comforter, and with the other, I leaned forward,
            steadying myself with my palm. I could feel the texture of the
            daisy-patterned stitching of her bedspread against my skin, yet I
            could feel it too in a strange time-transcendent manner, as if the
            fabric beneath my fingers were the manifestation of all the
            blossoms of my life:

            <br /><br />

            (all of them, the dried petal dust and pollen-faced suns and
            hard green buds, the summers past of sweet cut grass and fragrant
            mud, the patch near the birdhouse where the saturated earth sucked
            toes into its clotted embrace, the steep incline of the hill above
            it, and beneath the porch, the hidden tunnel of pebble where
            light poured through the cracks in the wood panels above like
            letters beneath a door or like ancient memories leaking through the
            fissures in the narrative of an intoxicated mind...)

            <br /><br />

            I had one hand upon the daisy bed, fingers spread, balancing the
            weight of my upper body and stabilizing my nervousness, my
            secret nervousness quieted by the alcohol in my veins. I leaned
            forward and her eyes surveyed me, questioningly. Was she seeking
            an answer? The space between her upper lip and nose seemed so
            endlessly inviting, a bed upon which to rest and to be revived,
            a garden upon which to plant and sow and seed and feed. And
            I leaned forward, slowly, hesitantly, and I looked at her and
            she gazed at me. She moved forward, then, and we both were
            converging the slightest bit, and suddenly like the tide
            collapsing upon the shore or a tree bough bent under snow finally
            surrendering to its sweet burden, our lips at last met, seasons
            colliding (I the winter and she the summer, antitheses united by
            this moment). I tumbled forward, wanting to consume her, her
            energy and being and essence, and I felt my hand moving
            toward her, pulling her closer, brushing back the hair before
            her eyes, my fingertips Bedouins upon the desert sand, gypsies
            caravanning with a wanderlust insatiable and everlasting,
            unwavering.

            <br /><br />

            I leaned forward, looking at her, surveying her. My hand was upon
            her thigh – no, of course it wasn’t. None of this occurred – my
            hand explored the bed of textile daisies, my mind wandered the
            desert terrain, her essence a body of water amongst arid plains,
            liquid soul and spirit, and oh! to be united – but no, she on
            the telephone, distracted, speaking with her friend, whomever,
            whomever it was. And when she finished the call, placed the phone
            in its cradle, she looked at me, quizzically, perhaps trying to
            discern this expression; my face had deceived me. My hand moved no
            nearer toward her than did my mouth, I became no more familiar
            with her body than with her soul, and the extent of my journey
            was measured only by the number of stitches upon this floral print,
            this contrived garden, false paradise, embroidered fabrication.

                </div>
              </div>
            </div>
          </div>
        </div>

        <p>
          <span className="redText">What was this</span>? Iris wondered,
          slamming the journal shut, &amp; tossing it away from her, feeling
          confused, exposed, caught in the act, as though she had been
          clandestinely reading someone else’s diary &amp; had come across a
          secret she’d wished she hadn’t uncovered, &amp; at that very moment,
          the owner walked into the room, saw the realization taking shape on
          Iris’s face, &amp; this revelation was made so much worse.
        </p>

        </h3>

			<div className="centered">

				<Link to="/chapter4b">
					<img
					className="arrow"
					src={process.env.PUBLIC_URL + "../icons/button_L.gif"}
					alt="left arrow"
					/>
				</Link>

				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        <Link to="/">
					<img
						className="arrow"
						src={process.env.PUBLIC_URL + "../icons/home_green.gif"}
						alt="home icon"
					/>
				</Link>

				</div>

				<div className="pgNum">
					9
				</div>

			</div>
      </div>


	)};
};

export default Chapter4c;

import React, { Component } from "react";

class About extends Component {

	constructor() {
			super();
			this.state = {
				width: window.innerWidth,
			};
		}

		componentWillMount() {
			window.addEventListener('resize', this.handleWindowSizeChange);
		}

		// make sure to remove the listener
		// when the component is not mounted anymore
		componentWillUnmount() {
			window.removeEventListener('resize', this.handleWindowSizeChange);
		}

		handleWindowSizeChange = () => {
			this.setState({ width: window.innerWidth });
		};


render() {

		return (
				<div>

					<div className="chapterBox">
						<div className="lilacBox">
		      		  	<h1>
								 		<span className="evergreenText">about</span>
									</h1>
		        </div>
					</div>

					<div className="chapterBody">

						<h3>

							<p>
								over a decade ago <span className="eggplantText">i awoke
								from a dream</span> &amp; started drafting this story.
								the moon bled on my pillowcase, the phone receiver in my hand,
								&amp; <span className="goldText"> daisy petals upon
								my lips</span>. i scribbled chapter 4 in my notebook, my
								curling text inane in the darkness but it felt as though
								<span className="roseText"> the glow of her
								skin lit the page</span>.
							</p>

							<p>
								since then, i've worked simultaneously
								<span className="evergreenText"> forwards</span> &amp;
								<span className="redText"> backwards</span>.
							</p>


							<div className="chapImgContainer">
								<div className="amethystBox">
									<div className="chapImgBorder">
										<img
												className="chapImg"
												src={process.env.PUBLIC_URL + "../images/curtain.jpeg"}
												alt="view out a window" />
									</div>
								</div>
							</div>

      				<p>
								this is a work of <strike>fiction</strike>
								<span className="oliveText"> my mind</span>. names, characters,
      					places, &amp; incidents either are products of <strike>the author's
								</strike> <span className="pinkText">your imagination</span> or
								are used fictitiously. any resemblance to actual events or
								locales or persons, living or dead, is entirely <strike>fateful
								</strike> coincidental.
							</p>

      				<p>
								this is all a <strike>simulation</strike> <span className="robinText">
								dream</span>.
							</p>

							<span className="mauveText">content warnings:</span>
								<br />
									&nbsp; &nbsp; • alcohol use <br />
									&nbsp; &nbsp; • sex
					</h3>


				</div>
			</div>

	)};
}

export default About;

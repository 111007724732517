import React, { Component } from "react";
import { Link } from 'react-router-dom';

class Chapter5b extends Component {

	constructor() {
			super();
			this.state = {
				width: window.innerWidth,
			};
		}

		componentWillMount() {
			window.addEventListener('resize', this.handleWindowSizeChange);
		}

		// make sure to remove the listener
		// when the component is not mounted anymore
		componentWillUnmount() {
			window.removeEventListener('resize', this.handleWindowSizeChange);
		}

		handleWindowSizeChange = () => {
			this.setState({ width: window.innerWidth });
		};


render() {

	return (

		<div>

			<div className="chapterBox">
				<div className="robinBox">
							<h1>
								<span className="evergreenText">chapter 5: </span>
								saturn's jubilee
							</h1>
				</div>
			</div>

			<div className="chapterBody">

				<div className="pgNum">
					11
				</div>

				<h3>

				<p>
					She heard someone calling to her, startled that this person who sought
					her out was already knowledgeable of her name – &amp; regrettably she
					learned it belonged to one of her former high school teammates.
				</p>

				<p>
					“Hey, girl,” she said, a rodent-like sneer spreading across her face,
					her unpleasant tequila-breath making Iris’s nose twitch.
				</p>

				<p>
					“It’s been a long time,” Iris said, attempting to feign interest in
					their exchange. She could no longer remember her name. Years ago they
					had been friendly with each other, but solely out of obligation; Iris
					was better at blending in with the others when she pretended to care
					about clothing brands &amp; makeup application techniques, she had
					learned.
				</p>

				<p>
					“Girl, I’d say it has been,” her teammate gushed, oblivious to Iris's
					lack of reciprocal enthusiasm. “The word is you’ve been wild at college!
					You lost your bra in a snowbank? How crazy!” she cackled.
				</p>

				<p>
					“How did you hear about that?” Iris scoffed. Despite the time
					that had passed, her teammate seemed not to have changed at all.
					<span className="mintText"> Her lifeblood was still gossip &amp;
					vicarious experience</span> – the poor thing
					must lead such a boring existence, Iris thought, aware of her
					superciliousness but feeling too that in some ways perhaps it was
					justified. In high school, the other girl had derided Iris because of
					her reputation for frequent all-nighters, poking fun at the bags under
					her eyes &amp; the overall unkemptness of her sleepless personhood the
					next day. Though these long nights were often the result of her tendency
					to procrastinate on assignments, sometimes Iris would recreationally
					deprive herself of sleep, though, as this could be a sort of drug in
					&amp; of itself. Now that her vice of choice was resolutely substance
					(whether it be alcohol or smoking, or something else...), this had
					become the other girl's source of bullying fodder.
				</p>

				<p>
					“Remember the blizzard at the beginning of this semester? It swept
					across the entire East Coast,” Iris began. She hardly felt obligated
					to provide this now near-stranger with an explanation, but as the topic
					had already been breached, she thought that she might as well have fun
					with it, fortified by the beer &amp; wine &amp; whatever else she’d
					already consumed. “The storm was still raging as I walked back to my
					apartment after a party, &amp; I don't know what came over me,” Iris
					continued, slowing her speech now &amp; opening her eyes wide for
					dramatic effect, “but I must have been feeling particularly Buddhist
					because I recall musing on the sad absurdity of my connection to
					physical things. I decided I should lose a few articles of clothing on
					the way home, with the intent of preparing my body for its journey
					towards enlightenment.”
				</p>

				<p>
					“You're so weird,” her teammate bellowed, her voice thick with judgement,
					&amp; yet tinged with... something else, a hint of envy? Yes,
					admittedly Iris was weird, eclectic, unconventional – but in being as
					she was, there was liberation. “Why the bra, though? Why not your jacket?”
				</p>

        <p>
					 “I wasn't trying to <i>expedite</i> enlightenment, I was just
					 attempting to prepare for it,” Iris retorted, &amp; then quickly
					 added, “Excuse me – I need to use the bathroom” before quickly
					 sliding past the other girl &amp; preferably out of her sight. She
					 recalled the actuality of that night in the snow, a few months
					 prior: Iris had been in someone's bedroom,
					 <span className="lilacText"> naked &amp; up to no good</span>,
					 when she had begun to feel the anger of the Irish Wines she'd thrown
					 back earlier (her name for a plastic cup of budget wine spiked with a
						low-quality liquor). She decided to depart before she made a
					 spectacle of herself. In her haste, she'd left her blouse in their
					 room &amp; fled the scene accidentally wearing their black skinny jeans
					 instead of her own. She knew she'd stuffed her panties in her purse,
					 &amp; her bra in the pocket of her parka, but in treading through the
					 rising drifts of snow as the sky buried the earth below it, the bra
					 slipped out unnoticed, the black satin with pink embroidered
					 flowers progressively covered by the several inches that were still
					 to fall before the storm finally relented late the next day.
    	 </p>

		   <p>
					This was the type of memory on which Iris didn't wish to dwell right
					now, so she retreated to a shadowy corner of the sitting room so that
					she might observe the others rather than ruminate. Everywhere there
					were bodies, so many bodies, <span className="redText"> indulging
					in drugs &amp; booze &amp; pleasures of the flesh</span>, gyrating to
					the thumping music (muscles flexing, eyes darting about, seeking a
					dance partner, another body to push your own against. This bizarre
					act of courtship before fucking was laughable but it was essential,
					Iris had learned, as a good dancer was more likely to be a good kisser,
					&amp; to be both, he was more likely to be good in
					bed). Her thoughts turned to memories of her many past naïve attempts
					at using corporeal union as some sort of means of
					<span className="goldText"> uncovering the mysteries of the soul </span>
					housed inside its fleshy, aging shell, but oh, they’d mostly failed,
					the darkness of the dorm rooms &amp; the alcohol-dulled thoughts &amp;
					the way in which the hand touched another’s skin but nothing more, &amp;
					reaching &amp; reaching couldn’t overcome that disconnect. Despite
					both parties’ most valiant efforts, still there is the space between,
					&amp; perhaps that is why people cling together so desperately,
					vainly trying to deny the chasm that threatens to overwhelm them. The
					evanescent gropes in the dark, fleeting gasps for air, a moan, climax
					building, the crescendo, &amp; the lurch, but it’s alone, so alone –
					crying out at the top not for the pleasure, but for the pain, the
					epiphany of solitude when this seemingly joint journey is undercut by
					the revelation that it has only been a separate plunge into the
					cavern of a separate mind – <span className="evergreenText"> but this
					was precisely the sort of thing she desperately longed to disregard
					right now</span>! Frustrated with herself, &amp; with her wandering,
					incessant thoughts, she traded the shadows of the sitting room for
					the luminous but bleary haze of the kitchen.
		  </p>

	    <p>
					“<span className="cornflowerText">Take a shot with us</span>!” a girl
					in the kitchen beseeched Iris, gesturing to her friend sitting on the
					counter, the shot glasses &amp; vodka beside her, but Iris responded
					only with a quick smile &amp; her own act of gesturing to the wine
					bottle still dangling from her wrist. She continued past the sink as
					the image of its contents (empty beer bottles, cigarette butts, a
					half-eaten slice of pizza) were involuntarily replaced with the
					half-light of the dorm room in her mind’s eye, illuminated by the
					string of lights pinned to the wall, &amp; outside the streetlight
					reflecting in the wet gloss of spring rain on the sidewalk. Initially,
					in this collegiate den of benevolent expectation &amp; eagerness (she
					was a filly, her coat still gleaming with the dampness of birth,
					precariously attempting to lift herself onto her feet), she had tried
					to see the sparkle in each of these encounters, the whimsical tales
					or novel perspectives these one-time bed-partners could share. They
					all spoke of beautiful things, scattered between the trivialities
					that are commonplace in trying to bridge the gap from stranger to
					acquaintance in a few mere hours. The first had envied Iris her youth
					(she a freshman, he a senior), another spoke of the necessity of
					trying LSD at least once in one’s lifetime, &amp; still another
					described the way in which one could smell the rain on the desert sand
					miles before it reaches civilization, a harbinger of its approach.
					She sought aesthetics amidst the hazy rubble of these bedspring-born,
					nighttime scraps. She was a <span className="pinkText">voyeur to her
					own experience</span>, capturing the worthy fragments &amp; afterwards
					expelling them through her fingers into the pages of her journal, her
					words at times fallible &amp; inexact but nonetheless hers, always hers.
	    </p>

	    <p>
				Yet after three years of this, <span className="eggplantText">she had
				become fully disillusioned</span>.
	    </p>

	    <p>
				Iris had just finished a near-complete circle through the connecting
				rooms on the ground floor while deep in thought, &amp; now found herself
				in the dining room where party-goers were seated at the long table,
				playing some drinking game with a deck of cards. They looked absurd,
				wearing denim cutoffs &amp; sneakers &amp; backwards caps &amp; slinky
				halter tops &amp; neon pumps, sweating &amp; swearing, but seated at
				the oak table with the floral, tasseled runner &amp; the lace placemats,
				the decorative rug underfoot &amp; the grandfather clock chiming in the
				corner, this pack of youth ignorant to the antique upholstery beneath
				them. They couldn’t have looked more out of place had they been pigs in
				vests &amp; jackets, &amp; sheep in paisley rompers, perched on
				the chairs before this spread of beer cans &amp; spades &amp; diamonds,
				clubs &amp; hearts. Perhaps that's precisely what this was, Iris
				thought cynically, a gathering of animals in garments, getting together
				for a cheap laugh &amp; a quick squeeze, desperately pretending that
				they were something different.
	    </p>

	    <p>
				She sighed, realizing that she'd been in every room on the ground floor
				&amp; still she hadn't found August. Maybe it was for the better – she
				would undoubtedly be surrounded by men, as usual, as she was always the
				focus. Admittedly, how could it be any other way?
				<span className="robinText"> They weren’t the only ones entranced by
				her</span>....
	    </p>

    </h3>

			<div className="centered">

				<Link to="/chapter5">
					<img
					className="arrow"
					src={process.env.PUBLIC_URL + "../icons/button_L.gif"}
					alt="left arrow"
					/>
				</Link>

				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        <Link to="/chapter5c">
          <img
            className="arrow"
            src={process.env.PUBLIC_URL + "../icons/button_R.gif"}
            alt="right arrow"
          />
        </Link>

				</div>

				<div className="pgNum">
					11
				</div>

			</div>
      </div>


	)};
};

export default Chapter5b;

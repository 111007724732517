import React, { Component } from "react";
import { Link } from 'react-router-dom';

const header = (
    <div>
    <Link to="/">
      <img
        className="header"
        src={process.env.PUBLIC_URL + "../images/header.png"}
        alt="header" />
    </Link>
    </div>
);

class Header extends Component {

    render() {
  return(
      <div>
        {header}
      </div>
    )
  }
};

export default Header;

import React, { Component } from "react";
import { Link } from 'react-router-dom';

import Chapter1Header from './Chapter1Header';

class Chapter1b extends Component {

	constructor() {
			super();
			this.state = {
				width: window.innerWidth,
			};
		}

		componentWillMount() {
			window.addEventListener('resize', this.handleWindowSizeChange);
		}

		// make sure to remove the listener
		// when the component is not mounted anymore
		componentWillUnmount() {
			window.removeEventListener('resize', this.handleWindowSizeChange);
		}

		handleWindowSizeChange = () => {
			this.setState({ width: window.innerWidth });
		};


render() {

	return (

		<div>
      <Chapter1Header/>

			<div className="chapterBody">

      <div className="pgNum">
				2
			</div>

      <h3>

				<p>
					<span className="chapFW">I</span>ris awoke with the taste of mortality
					on her tongue - <span className="lilacText"> <i> am I living
					this life fully enough? </i></span>The curtains beside the bed
					fluttered in the breeze, &amp; slowly, Iris opened her eyes, holding onto
					this moment that was not yet complete wakefulness, but rather was still
					painted in the watercolor hues of dreams. She had been in the orchard
					again, or was it a field? The stale &amp; suffocating air had prickled her
					flesh &amp; overhead the sky was grotesque, a swirling sea of churning clouds
					the color of gravestones. The wind twisted her hair about her mouth
					&amp; neck, unnerving in its lack of restraint, its disobedience. She had
					been barefoot, a thin dress flirting with her electric skin, &amp; she
					remembered moths being everywhere, <span className="oliveText">
					impossibly</span>, it was the wrong season &amp; the wrong time of
					day but they sailed on the air like suspended flower petals.
			</p>

			<div className="chapImgContainer">
				<div className="roseBox">
					<div className="chapImgBorder">
						<img
								className="chapImg"
								src={process.env.PUBLIC_URL + "../images/chapter1.jpeg"}
								alt="stormy sky" />
					</div>
				</div>
			</div>

			<p>
				Broken cornhusks crosshatched the muddy ground underfoot.
				<span className="robinText"> Had she been alone?</span> She thought
				she had sensed another who she failed to see; she felt certain of this,
				because the usual overwhelming solipsism had subsided for a moment, the
				loneliness had been punctured. Something had been coming, impending, a
				darkness that shrouded everything. Iris turned her eyes to the sky in
				anticipation, then to the horizon at every edge, but she saw nothing
				besides the corn stalks, &amp; perhaps apple trees on the hillside.
				Nevertheless, something was moments from realization, an occurrence that
				<span className="mauveText"> destiny had known of
				since the dawn of time</span>. It was to be made manifest, &amp;
				just as she ought to have learned of it, Iris abruptly became aware of the
				heaviness of her body on the mattress &amp; the hot air rising from her skin.
				The stormy tumult had been replaced with the familiar clutter of her bedroom
				(the stacks of books on every surface reaching towards the ceiling; the
				thrift-store clothes draped over the desk chair, leathers
				&amp; velvets &amp; sequins; memorabilia from adolescence onward that
				coexisted begrudgingly with newer, more eclectic finds from estate sales
				in the more recent years; &amp; everywhere, the comforting disarray of all
				the other things that had no designated place of their own).
			</p>

			<p>
				She had awoken too early; the sun was only just rising, bathing the sprawling
				meadow outside her window in a growing glow. The sight was
				<span className="eggplantText"> beautiful &amp; rare</span>, but it was
				too soon to leave her bed. Iris had returned home the night before,
				having just completed another year at college, &amp; the
				journey back merited sleeping in. So, she would rest another few hours,
				&amp; then attempt to face the day.
			</p>

    </h3>

    <h2>***</h2>

    <h3>

      <p>
        <span className="mintText"> The weather was mild for nascent June</span>,
				&amp; it fostered in Iris thoughts of airy old country homes, wooden
				floors dressed in dust &amp; floral quilts draped on a couch upholstered
				with a cow-spot pattern, screen doors that blew open &amp; slammed shut
				with the wind, mismatched dishware, &amp; braided rugs in the kitchen
				where sweet cream &amp; biscuits wait on a white-washed dinner table.
				These were the impressions this breeze-born day encouraged, &amp; Iris
				allowed them to flit through her mind as she slipped her small leather
				knapsack straps onto her bare shoulders &amp; mounted her bike to ride to
				the farmer's market downtown.
			</p>

			<p>
				The bike's tires kicked up gravel in her wake as she pedaled from her
				unpaved driveway to the main road that would take her to town. The
				countryside spread out in all directions before her, stretching
				expanses of green that never seemed to meet the sky, even miles away.
				As she divided the land atop her bike, Iris realized, listening to the
			  low hum of the field insects &amp; the whispers of the tall grass in the
				wind, that it felt much more like spring than like summer. It felt as
				though the crocuses ought to be swelling into blossom, tender green
				sprouts emerging from trees' slender fingers, an all-around timid
				warmth coaxing new life to emerge as winter receded, the sort of
				weather that inspires <span className="goldText">'sonnets' for strangers
				</span> -
		   </p>

				<div className="diaryBox1">
					<div className="diaryBox2">
					    <div className="diaryBox3">
					       <div className="diary">

					          There is a place behind my eyelids I can reach if I force myself
					          forward, amidst shadow-textures and light-spots, and there I can
					          explore the lines on your hand, the contours of your body, the
					          cracks in your story, the creases of your smile, the crevices of
					          your soul. And there, one with the twistings of shapes behind the
					          veil of skin lids, there is a breeze that carries me up through a
					          fracture in a floorboard, because the air is at last melting the
				            leagues of snow we’ve been drowning beneath... and as the soul swells
				            with warm flush at this weeping, dripping thaw, I realize that
				            even closed, my eyes will not stay still, outlining your shape.

					        </div>
					    </div>
					</div>
				</div>

			<p>
				Certain strangers had unique influence over Iris, though they'd
				never know it. A memorable article of clothing, or a strange but
				charming unusualness to their appearance, an aura of magnificence -
				any of these features might cause Iris to hold their images in her
				gaze a few moments longer than usual. It was the
				<span className="amethystText"> mystery of their being </span>
				that enchanted her, the potential for the story she could devise &amp;
				attribute to them, &amp; for this reason, it was critical they never
				actually speak to her, for fear that they'd <span className="redText">
				break the spell</span>. She could create the illusion, but they had the
				power to destroy it. And so, it was unsurprising that Iris found herself
				intrigued by a stranger she encountered upon her arrival to the farmer's market.
			</p>

			<p>
				It was still early in the season, but there was produce (summer squash,
			  arugula, rhubarb), &amp; otherwise the tents were filled with homemade
			  breads - braided challah, flour-dusted sourdough, earth-brown rye -
			  &amp; other artisanal goods, berry jams &amp; citrus marmalades, jarred
			  honey &amp; dried herbs. The great hauls of the harvest weren't expected
				until nearer the end of summer. Amidst the sway of the market's
	    	patrons, rippling between the tables as though laurel leaves tickled
			  by the etesian wind, Iris's line of sight beheld a slender girl, likely
			  of similar age. Her straight brown hair was nearly as long as Iris's
				own characteristic strawberry-blonde curls, &amp; it tumbled down her
			  back half-obscuring her jade tank-top, which complimented her olive
		   	skin, stopping just short of her flowing floor-length skirt. What was most
			  striking, however, was the way in which she <span className="oliveText">
				floated a few inches above the ground</span> - her feet didn't quite
				touch - &amp; it suited her.
			</p>

    </h3>

		<div className="centered">
      <Link to="/chapter1">
        <img
          className="arrow"
          src={process.env.PUBLIC_URL + "../icons/button_L.gif"}
          alt="left arrow"
        />
      </Link>

			&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

      <Link to="/chapter1c">
        <img
          className="arrow"
          src={process.env.PUBLIC_URL + "../icons/button_R.gif"}
          alt="right arrow"
        />
      </Link>
		</div>

		<div className="pgNum">
			2
		</div>

	</div>
</div>

	)};
};

export default Chapter1b;

import React, { Component } from "react";
import { Link } from 'react-router-dom';

class Chapter4 extends Component {

	constructor() {
			super();
			this.state = {
				width: window.innerWidth,
			};
		}

		componentWillMount() {
			window.addEventListener('resize', this.handleWindowSizeChange);
		}

		// make sure to remove the listener
		// when the component is not mounted anymore
		componentWillUnmount() {
			window.removeEventListener('resize', this.handleWindowSizeChange);
		}

		handleWindowSizeChange = () => {
			this.setState({ width: window.innerWidth });
		};


render() {

	return (

		<div>

			<div className="chapterBox">
				<div className="amethystBox">
      		    <h1>
								<span className="oliveText">chapter 4: </span>
								the yellow house
							</h1>
        </div>
			</div>

			<div className="chapterBody">

				<div className="pgNum">
					7
				</div>

				<h3>

        <p>
          <span className="chapFW">I</span>ris put on her headphones, securing
          the portable cassette player in a pouch on her waist, &amp; mounted
          her bike, starting out in the direction of August's house. Inside the
          device was a bootleg tape of tracks she'd recorded while listening
      		to the local college radio station the last time she'd been at home
          for an extended period of time, &amp; while the songs she'd collected
          were poignant, the evocative atmosphere of their layering was at
          times crudely contrasted by the clips of radio show dialogue she'd
          accidentally captured as well.
      	</p>

    		<p>
      		She anticipated the trip would take perhaps thirty minutes or longer,
          upon a seldom-traveled road that connected the secluded houses
          dotting the farms out here in this part of the Northern Country. She
          had awoken that morning feeling different, <span className="mintText">
      		perhaps born-again</span>, even, a glow washing over her &amp;
      		bathing her worldview in a rosy light. She had sat cross-legged on the
      		mattress, looking down at her hands, her breasts, her stomach, with a
      		strange <span className="mauveText">hyperawareness of her phantom
          virginity</span>, as though her recent meandering youthful
          recollections had caused her mind to revert to some old neural state
          in which such memories of <span className="evergreenText">fearful, tingling,
          untouched skin</span> were strongest.
        </p>

        <p>
      		The headphones were damaged, &amp; so in one ear she listened to the
          song fragments interleaved with blips of incidental radio show banter,
          &amp; in the other Iris heard the lyric cicada &amp; the field cricket
          as she passed a stretch of corn, &amp; she remembered that night in
          town with time to kill, so she had driven around, illuminating the
          dark secrets of the streets with her headlights. She pulled into the
          lot beside a playground &amp; accidentally uncovered two teens
          embracing, the tangle of trees &amp; shards of nightfall their
          backdrop. Oh, young love, the inexperience, the eager, hungry gropes
          – she directed the car back onto the road, because
          <span className="robinText"> she too knew what that was like</span>.
    		</p>

        <div className="indexCardContainer">
          <div className="indexCard">

  					<h2>instructions:</h2>

  						<h3>stop reading for a moment. insert your headphones. press
              <u>play</u> on the radio clip below. close your eyes &amp; pause
              your life in the darkness, remembering what it was like to be like
              <u>iris</u>: you listen as you pedal across the remaining passage.
              travel back to that time &amp; place where you knew young love /
              casette tapes / untouched skin / the immortality of youth. don't
  						return to written words until the audio clip has dissolved as
              though a lover's kiss evaporating from your lips.</h3>
  			  </div>
        </div>

    		<div className="chapImgContainer">
    			<div className="lilacBox">
    				<div className="chapImgBorder">
      				<div className="soundcloudContainer">
      					<iframe
                  className="soundcloud"
      						title="radio1"
      						scrolling="no"
      						frameborder="no"
      						allow="autoplay"
      						src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/774626872&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true">
      					</iframe>
      				</div>
    				</div>
    			</div>
  		  </div>

        <div className="editorsNote">
        	i removed the file, it had some inappropriate parts
        </div>

        <p>
        		The earth had completed a full revolution around the sun in the time
        		since Iris &amp; Ezra participated in the science competition, &amp;
        		the high school was systematically being broken &amp; then pieced back
        		together. The old chemistry lab had been relocated, the cafeteria
        		descended from the main floor to the basement, &amp; entire portions of
        		the building were temporarily barred from entry. It was torn apart so
        		swiftly &amp; entirely that in recounting its layout now, Iris had
        		great difficulty trying to reconcile her memories of the new
        		functions of the many spaces with the
            <span className="eggplantText"> ghosts</span> of the walls &amp;
        		corridors that had once defined them.
        </p>

        <p>
        		The day had just barely begun that Friday when the squawk of the
            loudspeaker interrupted second period. "There is no need for alarm,"
            the principal announced, but construction workers had uncovered trace
            amounts of asbestos that morning, &amp; it was necessary that the
            school be evacuated until the source was identified &amp; contained.
            This year’s chemistry students rejoiced, having been granted several
            extra hours to assemble their posters before the competition the next
            day, &amp; Iris met Ezra outside at his car. It was still a couple of
            hours until noon, &amp; his mother wasn’t expected home until
            early evening...
        </p>

        <p>
        		Ezra lived downtown where houses huddled together as though for warmth
        		in this bitter climate. They drove on a street named after a mountain,
        		&amp; then found themselves in his bedroom with three windows, one of
        		which allowed access to part of the roof. They’d talked many times about
        		crawling out into the balmy night air &amp; admiring the way in which
        		the <span className="goldText">stars seemed to dangle in the sky</span> –
            but they never did, time passed them by before they’d had the chance.
            His bed was dressed in sheets striped by the primary colors, &amp;
            it was somewhat infantile in all honesty, but in retrospect, perhaps
            it was appropriate, Iris thought, this place where two inexperienced
            beings were to <span className="cornflowerText"> broach the unknown
            together</span>.
        </p>

        <div className="editorsNote2">
        	<br />
        	i also censored this part
        	<br />
        	<br />
        </div>

        <p>
      		He saved the condom, he told Iris later, &amp; she encouraged him to
      	  dispose of this strange token, so he bizarrely threw it down a storm
      	  drain, unleashing his seed unto the subterranean world. Together they
      	  hid the unused extras in a VHS case of Titanic where snooping eyes
          would fail to find them, unless one were seeking <span className="oliveText">
      	    romance &amp; tragedy</span>, &amp; perhaps Ezra &amp; Iris were too.
  			</p>

        </h3>

        <h2>***</h2>

        <h3>

  			<p>
      		Iris struggled somewhat in pedaling on the long gravel driveway that
      		heralded August’s house. It was immense, painted yellow though the
      		color had weathered a bit with age, &amp; surrounded by a sweeping
      		porch offset by the wildflowers that crept towards the railing. On all
      		sides, the land sprawled out until it touched the sky in the far
      		distance, some patches covered with cornstalks &amp; others with
      		grazing cows. Iris leaned her bike against the railing, lowered her
          headphones from her ears to rest curled about her neck, &amp; knocked
      		hesitantly at the front door, waiting for some sound of motion inside.
  			</p>

  			<p>
      		Failing to hear any answer, Iris tentatively grasped the doorknob,
      		curious to see if it would <span className="pinkText">yield to her
          touch</span>. The door opened easily, &amp; Iris stepped into the
          spacious foyer beyond it, a spiral staircase before her opening up
          into at least two balcony landings above it, perhaps even more, &amp;
          undoubtedly countless rooms stretching off from the halls as well.
  			</p>

				</h3>

				<br />

				<div className="centered">
					<Link to="/">
						<img
							className="arrow"
							src={process.env.PUBLIC_URL + "../icons/home_green.gif"}
							alt="home button"
						/>
					</Link>

				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

				<Link to="/chapter4b">
					<img
						className="arrow"
						src={process.env.PUBLIC_URL + "../icons/button_R.gif"}
						alt="right arrow"
					/>
				</Link>

			</div>

			<div className="pgNum">
				7
			</div>

		</div>
	</div>

	)};
};

export default Chapter4;

import React, { Component } from "react";
import { Link } from 'react-router-dom';

class Chapter5d extends Component {

	constructor() {
			super();
			this.state = {
				width: window.innerWidth,
			};
		}

		componentWillMount() {
			window.addEventListener('resize', this.handleWindowSizeChange);
		}

		// make sure to remove the listener
		// when the component is not mounted anymore
		componentWillUnmount() {
			window.removeEventListener('resize', this.handleWindowSizeChange);
		}

		handleWindowSizeChange = () => {
			this.setState({ width: window.innerWidth });
		};


render() {

	return (

		<div>

			<div className="chapterBox">
				<div className="robinBox">
							<h1>
								<span className="evergreenText">chapter 5: </span>
								saturn's jubilee
							</h1>
				</div>
			</div>

			<div className="chapterBody">

				<div className="pgNum">
					13
				</div>

				<h3>

	        <p>
				    Iris learned that Ezra had been talking to other girls, his life
						striding on ahead, as she stagnated at home in his absence, her legs
						cut off at the knees. Just after Thanksgiving, he broke up with her
						by phone, calling her back a half hour later (a box of tissues had
						already been executed; they lay spent on the floor at her feet). He
						said it’d only taken him five minutes before he acknowledged he’d
						made a grave mistake, emphasizing this length of time as though it
						were the most rapid of realizations, but Iris felt this was
				    four minutes and fifty-five seconds too slow. They tried to make it
						work, but he ended it again a few days later, then again retracted
						it, &amp; finally put this poor disfigured beast (masquerading as a
						relationship) out of its misery for the third &amp; final time.
						This all occurred at the height of college application season, &amp;
						it was a miracle that Iris’s anguish didn’t compromise her eventual
						acceptance to her top-choice school.
	        </p>

	        <p>
				    In his eyes, What they had done was forbidden, she thought (the
						warm air of Raleigh’s body negating the need for a sheet), &amp;
						maybe it had eaten away at them, <span className="mauveText"> a
						fungus digesting their organs, this gangrene of the heart</span>.
						She’d never imagined their days were numbered, &amp; this wasn’t
						because she was blinded by the brilliant rays of young love’s
						enchantment, but because they had been destined to be together! The
						birthday order, the science fair (she kicked the sheet off the bed
						in her fervor) - but distance destroyed them. Still, sentences from
						the love letters he had written return to her, phantom syllables
						tearing at the fabric of her mind: <i>“
						<span className="amethystText">What in the world can I
				    say to the love of my life as she turns seventeen years old</span>?”
						</i>
	        </p>

	        <p>
				    “Eternity,” “forever,” ...these words compelled her to destruction.
						It was so cruel that time had done this to them, &amp; she longed to
						<span className="evergreenText"> obliterate all markers of its
						existence</span>, the rising &amp; falling of the sun, the changing
						of the seasons, the turning of the tides, the glaring numbers on
						the digital clock &amp; all of the world’s timepieces... the
						reminders of the limitations of this
						<span className="redText"> fallible, vulnerable, feeling, aging
						body</span>.
	        </p>

	        <p>
				    Perhaps they had been Romeo &amp; Juliet, but it had gone horribly,
						terribly wrong: <span className="goldText">they had broken up
						instead of dying</span>.
	        </p>

	        <p>
				    Even in the wake of good things (Raleigh’s gentle sleeping body,
						his soft mouth exhaling the whirling steam of dreams), the hideous
						still crawled out from the darkness of the past, unsatisfied with
						decaying, desisting.
	        </p>

	        <p>
				    Things were better now, Iris assured herself, &amp; – oh – in her
				    drunkenness, she’d forgotten August... but perhaps this was for the
						better tonight? She was uncertain, but decided she was not ready yet
						for sleep, so she carefully slid out of bed, turning to see
						Raleigh’s head droop forward slightly in her absence, but he did
						not wake. She crawled about, fumbling for her clothes, finding her
						bra dangling by one of its straps from the standing lamp near the
						door.
	        </p>

	        <p>
				    According to the watch on her wrist, the night was still young,
						&amp; the wave of sonic jubilation that greeted her when she
						slipped through the doorway &amp; into the hall agreed. The
						experience of walking in the strange yellow brightness of the
						corridor made her feel slow &amp; sober, so she descended the
						stairs intent on returning to the kitchen to prepare herself a new
						drink. Iris spotted the girl who had earlier asked her if she’d join
						them for a shot; it seemed she’d hardly moved from her perch on the
						granite countertop near the toaster. Iris approached her, reaching
						for the handle of cheap vodka near the girl, her small
				    face screwed up, pupils immense, her mouth gravely serious.
	        </p>

	        <p>
				    “I'm... not sure if I'm capable of moving,” she whispered to Iris,
				    her eyes widening further.
	        </p>

	        <p>
				    “Don't worry about Sibyl,” her stern-looking friend said forcefully.
						Iris gazed at the both of them for a moment, wondering if maybe she
						should do something (but what would she do anyway? What could she
						do?) - ultimately deciding to dump some vodka into a plastic cup
						&amp; then reverently lift the vessel to her mouth so that she could
				    take a small sip. She loved the way that straight liquor made her
						lips go numb. She leaned against the counter besides the girls,
						savoring this sensation &amp; their silence, recalling times past
						when her lips had been so bitten after some Saturday night that her
						friends asked if she was wearing lipstick. She avoided these inane
						practices of vanity (preferring instead just a modest concealer to
				    soften her freckles &amp; a black mascara to color her blonde
						eyelashes), &amp; as such their misinterpretation caused her to
						smirk inwardly at the filthiness of her secret truth. She didn't
						realize she'd closed her eyes until she felt fingers curl around her
						forearm.
	        </p>

	        <p>
				    “Hey, 'ris.” She lazily opened them &amp; peered directly into the
				    honey-colored eyes of August.
	        </p>

	        <p>
				    “Hey,” Iris murmured in response, at once captivated by the sudden
				    manifestation of the other girl, as though the
						<span className="robinText"> dexterous hands of her magical, murky
						mind had shaped this being from clay &amp; presented her here</span>,
				    precisely a moment before Iris realized that it was August she
						wished to see.
	        </p>

	        <p>
				    “I've been looking for you,” August revealed, playfully sliding her
						hand down Iris' forearm so that her fingers instead wrapped around
						the redhead's wrist, &amp; proceeded to lead her through the back
						hallway, her alacrity &amp; certainty of direction informing Iris
						that she did not plan to elaborate until they'd reached their
						destination. They dodged figures (two pressed together against a wall,
				    others scattered in the hallway, on couches &amp; floors) until
						they came to the small sitting room with the swinging screen door
						that granted access to the garden outside. It was quieter here,
						somewhat removed from the frantic rancor &amp; throbbing heartbeat
						of the foyer sound system. Seated cross-legged in the center of the
						room were a collection of other twenty-somethings, a few skinny
				    boys wearing thick-rimmed glasses, a girl with wild ringlets who
						kept smiling &amp; then making her mouth return to normal, again
						&amp; again (what could she be thinking about? Iris wondered).
						There were two displaced-looking fraternity guys, athletic caps on
						sideways, &amp; three girls with excessive eyeshadow. Iris ceased
				    her examination of the others as August guided her to an empty
						spot in the circle &amp; gestured to her to sit down. The only one
						still standing, August wrapped her lips around a bottle of rum,
						taking in the last ounce quickly, before slowly bending at the
						knees, her brown hair a curtain that obscured her face, &amp; she
						deliberately placed it down upon the hardwood floor. She seated
						herself &amp; announced “spin the bottle,” somewhat obviously,
						but nevertheless coy, a devious expression emerging upon her lips
						(those gorgeous lips)....
	        </p>

	        <p>
				    The room wobbled as someone spun the bottle, the walls rippling, warm and
				    flushing (or was that the alcohol?), and two people kissed, though Iris
				    missed it because her focus was fixated on the far wall, there was a
				    painting of a barn upon it, but it appeared to be melting, the red green
				    yellow was dripping down the blue wallpaper, what was happening? She
				    peeled her eyes away, returning to the circle, no one else had noticed
				    anything strange, it seemed, they were waiting for the bottle to cease
				    its revolution, it slowed, and stopped, pointing to Iris. She had not
				    seen who had spun it – and then she learned, her stomach lurching, it
				    had been one of the frat boys, he lumbered towards her, she couldn’t
				    move, she was holding her breath and she saw his face approaching, she
				    clenched her eyes shut, his open mouth slobbering around her pursed
				    lips, as though some sort of grotesque mollusk; oh, it couldn’t be
				    over quickly enough! She heard laughter and opened her eyes; the
				    others seemed amused by her thinly-veiled disgust. She blinked,
				    letting out a quick, false laugh herself, and impulsively she
				    looked again at the painting of the barn, which was safely and
				    quite normally contained within its maple picture frame.
	        </p>

        <p>
    The bottle was tossed again (the curly-haired girl quickly pecked a
    nondescript boy Iris vaguely recognized from high school; two of the
    skinny boys made out ironically), and then it was August’s turn, and
    Iris again held her breath, but this time the reason was entirely different.
    August reached forward, the bangles on her wrist sounding together like
    wind chimes, and the bottle spun for what seemed an impossibly long time,
    turning at a dizzying rate, reflecting the soft glow of the tea lamp on the
    side table as a sparkle in all of their eyes, and they waited for gravity,
    or physics, or some other colossal force to result in destiny. Iris saw it
    slow with the weight of its judgment, willing it to finally come to a rest
    before her, its glass mouth gaping at her, in awe, perhaps? August lifted
    herself up onto her heels so that she might lean across the circle towards
    Iris, and the other girl did the same, afraid to search August’s face for
    some indication of her thoughts on this matter, this occurrence. Despite all
    of the alcohol in her system, Iris could still feel her heart rate quicken,
    the voice inside her head reaching fever-pitch, chanting <i>this is
    happening… this is happening…</i> and suspended over the piece of
    glassware that had precipitated such an act of God (for, despite
    Iris’s unyielding atheism, how else could such fortune be made
    manifest?), their lips touched, and arguably, the course of history
    was changed forever.
        </p>

        <p>
    No one in the sitting room heard the soft sound, as though a thin
    piece of maple had snapped, but in the kitchen, rooted to the countertop,
    her pupils planets at eclipse, the girl called Sibyl whispered
    something – her vigilant friend struggled to catch the words: <i>“Red…
    green… yellow… dripping down, onto blue….”</i>
        </p>


        </h3>

			<div className="centered">

				<Link to="/chapter5c">
					<img
					className="arrow"
					src={process.env.PUBLIC_URL + "../icons/button_L.gif"}
					alt="left arrow"
					/>
				</Link>

				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        <Link to="/">
					<img
						className="arrow"
						src={process.env.PUBLIC_URL + "../icons/home_green.gif"}
						alt="home icon"
					/>
				</Link>

				</div>

				<div className="pgNum">
					13
				</div>

			</div>
      </div>


	)};
};

export default Chapter5d;

import React, { Component } from "react";
class Chapter1Header extends Component {
	render() {
		return (

      <div>

				<div className="chapterBox">
					<div className="pinkBox">
             <h1><span className="redText">chapter 1:</span> the revival</h1>
          </div>
				</div>

      </div>
	)};
};

export default Chapter1Header;

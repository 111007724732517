import React, { Component } from "react";
import { Link } from 'react-router-dom';

class Chapter5c extends Component {

	constructor() {
			super();
			this.state = {
				width: window.innerWidth,
			};
		}

		componentWillMount() {
			window.addEventListener('resize', this.handleWindowSizeChange);
		}

		// make sure to remove the listener
		// when the component is not mounted anymore
		componentWillUnmount() {
			window.removeEventListener('resize', this.handleWindowSizeChange);
		}

		handleWindowSizeChange = () => {
			this.setState({ width: window.innerWidth });
		};


render() {

	return (

		<div>

			<div className="chapterBox">
				<div className="robinBox">
							<h1>
								<span className="evergreenText">chapter 5: </span>
								saturn's jubilee
							</h1>
				</div>
			</div>

			<div className="chapterBody">

				<div className="pgNum">
					12
				</div>

				<h3>

					<p>
							And suddenly, Iris’s thoughts of August were truncated - had she
							heard her name, once again? The card-players were absorbed in their
							game, she ascertained, none had noticed that she stood against the
							doorframe observing them, so it likely hadn't been them, but it
							sounded like a male voice, could it have been... Raleigh? She spun
							around, seeing him approach her from the living room
							<span className="roseText"> as though from
							a dream</span>, his grin growing as he came nearer.
			    </p>

			    <p>
						 “Raleigh!” she exclaimed, allowing herself to be wrapped up in his
						 embrace. He smelled of soil &amp; bourbon &amp; the t-shirt he was
						 wearing (sleeves cut off, the edges frayed) showcased his muscular
						 arms. They had been intimately involved in the past, a year or so back,
						 after having been introduced at a party by a mutual friend. Perhaps
						 they'd been lonely, or perhaps enchanted, but regardless
						 <span className="mintText"> their bodies had come to meet in the
						 dark</span>, &amp; things might have nearly felt real with him,
						 Iris had thought, but then she returned to school in upstate New
						 York &amp; he to Chicago.
			    </p>

			    <p>
						August hadn't been the friend who had introduced them, however, &amp;
						for this reason she was surprised to see him here now, in her home.
						She asked him if they'd somehow met recently.
			    </p>

			    <p>
						“Oh, this is her house?” he responded, avoid eye contact as he
						ruffled his sandy-blonde hair. “I was passing by,” he continued,
						giving her a small nervous smile now, “and... there were a few
						people smoking on the porch, they beckoned to me, &amp; I didn’t
						have the heart to disappoint them, so I wandered in
						&amp; by the will of fate, I found you.” He grinned more easily now,
						playfully.
			    </p>

			    <p>
						Iris nodded in response, recalling the people perched on the railing
						outside, their legs swinging as their hands moved cigarettes to &amp;
						from their lips; they had called out to her, too. But... his
						explanation was far too bizarre. “Wait... how were you just passing
						by?” she asked, her brow furrowed in confusion, her eyes entreating
						him to reveal that which he was undoubtedly hiding. “There’s
						nothing but field for miles around here.”
			    </p>

			    <p>
						Raleigh frowned. “I...” he started &amp; then stopped, sighing.
						“Iris, you never let anyone get away with the easy answer.”
			    </p>

			    <p>
						This was true, she recognized.
			    </p>

			    <p>
						“I’m trusting you with this knowledge,” he continued, “I’m... in a
						bit of trouble,” he laughed nervously, attempting to maintain the
						façade of his usual light-heartedness, but Iris could tell he was
						shaken. “I’ve been taking cover in the fields on my way out of town,
						but I needed a place to rest for the night, so I headed towards the
						sounds of this party...”
			    </p>

			    <p>
						“Shit, Raleigh,” she interrupted, reaching out a hand to touch his
						shoulder. “Will this... all of this, whatever's going on, will it
						be alright?”
			    </p>

			    <p>
						“I don’t know,” he muttered, eyes again downcast.
			    </p>

			    <p>
						Iris felt for him in this moment of need; she’d never seen him so
						unsure of himself. She reassessed his complexion &amp; disheveled
						appearance – with this new knowledge, she realized now that he
						wasn’t tanned but rather was sun-beaten, &amp; his clothing was not
						trendy but instead distressed from the elements. She hypothesized
						that his situation likely must be severe for him to reveal such
						insecurity &amp; uncertainty to her now. She was hesitant to ask
						what had happened; in the meantime, it might be better
						not to know.
			    </p>

			    <p>
						“August’s parents are away,” she offered. “I’m sure you can stay
						here for a few days, there certainly seems to be no shortage of
						space.”
			    </p>

			    <p>
						His face relaxed, and she was reminded of how attractive he was,
						the golden glow of his complexion offset by his turquoise eyes,
						with the crinkles at the edges....
			    </p>

			    <p>
						“What are you drinking?” he asked her, eager to change the topic
						&amp; forget about their exchange thus far. “So long as I’m here,
						we might as well have fun,” he teased, so Iris raised her bottle to
						answer him, swirling the wine about so that it splashed up angrily,
						a maelstrom in its glass encasement. “I’ll grab us some beers,” he
						suggested. “We'll finish the rest of that wine quickly, I'm sure.”
			    </p>

			    <p>
						“Okay,” Iris smiled, &amp; Raleigh grinned deviously, stepping
						backwards so as not to break eye contact with the girl until he’d
						surpassed the dining room perimeter &amp; had returned to the
						pulsating chamber that was the foyer. When he’d gone, Iris took
						another drink of wine – ah, how rather fantastic lips feel against
						the mouth of the bottle, she thought – &amp; with each sip, swig,
						or gulp, even – there is increased potential for new perceptions as
						its pink-red warmth spreads through the body, flushing the cheeks,
						widening the eyes, loosening the tongue.
			    </p>
		</h3>

		<h2>***</h2>

		<h3>

        <p>
			    Perhaps unsurprisingly, (<span className="cornflowerText">soft steps
					on the spiral staircase &amp; fingers intertwined &amp; the empty wine
					bottle abandoned beneath a coffee table</span>), Iris found herself
					in one of the house’s many bedrooms &amp; she was undoing Raleigh’s
					belt buckle &amp; he lifted her blouse over her head, the window was
					open &amp; they could feel the sweet grass breeze flutter against
					their flushed skin, the aria of the night-bugs ringing in their ears,
					she guided his fingers to her bra clasp, the hook releasing the fabric
					&amp; she flung it off, relishing this reunion of their skin,
			    &amp; as he kissed her, <span className="lilacText"> it was as though
					no time had passed since they’d last been together</span> (perhaps in
					another lifetime, they could have been proper lovers, but for now this
					reality had to suffice... &amp; maybe it could), her fingers knotted in
			    the unruly curls of his hair, she giggled &amp; pushed him backwards
					onto the bed, &amp; his laugh joined hers as he grabbed her around
					the waist &amp; pulled her on top of him. He drew blood to her neck
					with each gathering of the jaw, little rosy craters, the broken
					capillaries bruised gladiola petals, &amp; with each thrust, Iris felt
					herself pushed against an invisible wall which felt immoveable
			    in the unknowing moments between, but it gave with his weight,
					allowing for this impossible pressure, breathlessness, feelings of
					pain &amp; pleasure rising &amp; popping at the surface, more bubbling
					in their wake, everything growing as the waves of sensation &amp;
					tightness ripple outward &amp; here is his back, her hand kneading his
					shoulder blade, expelling the air from her lungs as that wall is
			    breached again, his hand cupping her breast &amp;
					<span className="oliveText"> their jagged breaths turn to
			    burning whimper</span>....
        </p>

        <p>
			    Raleigh fell asleep sometime after, his chin nuzzling the soft spot
					beneath Iris’s collarbone, his gentle breath rhythmically warming her
					cheek with each exhalation. Iris remembered how it hadn’t always been
					like this, brushing her bangs away from her eyes, her heart rate
					still not quite at its normal, slower pace. With Ezra, there had
					been the guilt, the fear, the confusion... she had been so scared,
					she wouldn’t let him finish even with a condom on. At the time,
			    she thought they’d been close, so much closer than she’d ever been
					with anyone before him; they were naked when they lost their
					virginity together, but for their necklaces, the Miraculous
					Medallions. She’d picked one out for him at Christmas, knowing he’d
					cherish it because of his devotion to his faith, &amp; he’d surprised
					her with one also, uncannily, though she was openly unreligious:
			    his purchase seemed more motivated by his interests than by hers. The
					Virgin Mary will quench the thirst of the loyal while in purgatory,
					he told her, &amp; she was astounded that he believed this, that he
					foresaw their obligatory journey to a fiery waiting room.
        </p>

        <p>
			    Then she recalled <i>that</i> time, in the bathroom beside his room,
					had this been the third time they’d tried sex? It was still so new,
					&amp; still it hurt, why did it still hurt? They scheduled this act
					when they were certain Ezra's mother wouldn’t be home, &amp; Iris was
					too worried for it to be truly pleasurable, this wasn’t making love,
					she cried, her back sliding down the bathroom door as she
					crumpled on the floor, trying her hardest to remain silent so he
					wouldn’t hear her, this wasn’t that. She held her knees to her chest,
					wondering if this could be better if it was with... no, she could not
					think about that time (her face contorting now on the pillow in the
					dark of this borrowed bedroom). <i>This</i> thought still stabbed her:
					it had been her deepest secret for so long.
        </p>

				<p>
					Iris had only been a junior in high school; she hadn’t yet been
					liberated from such self-shaming ideologies, she reasoned, her face
					relaxing again, &amp; so his religious guilt had dirtied her as well,
					&amp; despite the time that had passed, these recollections still
					pained her, <span className="goldText"> a palm of salt that searched
					her skin for a raw spot somewhere &amp; then massaged it into the
					wound</span>. That same cruel salt-crusted hand brought forth the
					worst of their time together, Ezra's first few months at college in the
					South (while she was beginning her last year of high school in the
					North), when his pain travelled the thousands of miles that separated
					them in the form of his wavering voice on the telephone. He had
					threatened to harm himself, &amp; Iris buried herself beneath blankets
					in the corner of her room farthest from the door, behind the bed, so
					that her family might not hear her hysterical sobbing, the cracking of
					her voice, a spinning, whipping vertigo overtaking her as she begged
					him not to do that to himself, to her.
				</p>


        </h3>

			<div className="centered">

				<Link to="/chapter5b">
					<img
					className="arrow"
					src={process.env.PUBLIC_URL + "../icons/button_L.gif"}
					alt="left arrow"
					/>
				</Link>

				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

				<Link to="/chapter5d">
          <img
            className="arrow"
            src={process.env.PUBLIC_URL + "../icons/button_R.gif"}
            alt="right arrow"
          />
        </Link>

				</div>

				<div className="pgNum">
					12
				</div>

			</div>
      </div>


	)};
};

export default Chapter5c;

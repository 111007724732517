import React, { Component } from "react";

class Footer extends Component {
	render() {
		return(
			<footer>
	      <h3>
					&copy; 2019 - 2021 underworld iris
				</h3>
      </footer>
		);
	}
}

export default Footer;

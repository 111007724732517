import React, { Component } from "react";
import { Link } from 'react-router-dom';

class Chapter4b extends Component {

	constructor() {
			super();
			this.state = {
				width: window.innerWidth,
			};
		}

		componentWillMount() {
			window.addEventListener('resize', this.handleWindowSizeChange);
		}

		// make sure to remove the listener
		// when the component is not mounted anymore
		componentWillUnmount() {
			window.removeEventListener('resize', this.handleWindowSizeChange);
		}

		handleWindowSizeChange = () => {
			this.setState({ width: window.innerWidth });
		};


render() {

	return (

		<div>

			<div className="chapterBox">
				<div className="amethystBox">
      		 <h1>
					 	<span className="oliveText">chapter 4: </span>
						the yellow house
					</h1>
        </div>
			</div>

			<div className="chapterBody">

				<div className="pgNum">
					8
				</div>

				<h3>

          <p>
            “August?” she called out hesitantly, her voice echoing stupidly off
            the hardwood floors underfoot.
          </p>

          <p>
            “Second floor,” a voice called down. Iris looked up at the sound of
            the answer, taking in the ornate chandelier overhead &amp; the sapphire
            walls of the upper levels. She began the ascent, her hand skating up
            the oak banister as she climbed. She was guided aimlessly by some
            indiscernible sense of where the other girl must be – wandering down
            the first hallway on the second floor towards a presumably ajar
            bedroom door – &amp; just ahead, at the end of this corridor, Iris
            could see a triangular patch of light on the carpet, a small
            <span className="mauveText"> polygon of illumination</span> likely
            granted by the afternoon sun filtering through one of August's windows.
            She peered into the doorway, seeing August sitting cross-legged on
            the bed. She smiled, saying, “You found me.”
          </p>

      		<p>
          	“Hey,” Iris greeted her, “have you always lived here? I can't remember
          	visiting when we were younger,” she wondered aloud, falling into her
            predictable pattern of making small talk. Iris was shy &amp; maybe a
            bit awkward at first, admittedly, but she was confident in the shining
            qualities that inevitably emerged as she became more comfortable with
            the other person: undying loyalty, witty cynicism, a weakness for all
            of the beautiful things that hide in the routine of day-to-day life,
            waiting for a keen eye to recognize them for what they are, often
          	<span className="roseText"> heartbreaking in their transience</span>,
            but <span className="robinText">poignant in their simplicity</span>,
            their  -
          </p>

          <p>
          	“Come join me,” August’s voice penetrated the cloud of Iris’s
          	introspection, wrapping itself around the girl as though a lasso
            &amp;	luring her willingly, eagerly, towards the bed where August was
            lounging. “<span className="mintText">I’m craving... social
            interaction</span>,” August exclaimed, her eyes flickering. She
            gestured to a list of names on the duvet &amp; a phone beside it.
            “So, I’m planning a party.”
          </p>

          <p>
            Iris tentatively sat down at the foot of the bed &amp; asked August
          	when this would be.
          </p>

          <p>
          	“Probably this upcoming weekend,” she replied, “or maybe sooner, it
          	depends on how long I can wait. Wine?” August grinned before
            gesturing towards the bottle of red on the bedside table.
          </p>

          <p>
          	“Yes, please.” Iris curled her legs underneath herself, hoping to appear
          	more at ease than she actually felt, the embroidered floral pattern of
          	the viridian bedspread tickling her bare skin.
          </p>

          <p>
          	The sunlight entered August's room from the window behind
          	her &amp; illuminated her, magnifying itself as it filtered through
          	the wine glass, the stem between her fingers, &amp; Iris observed her,
          	the golden skin, the blush off-the-shoulder top offset by her long
          	brown hair, &amp; now this ball of light resting in her palm, as
            though she'd <span className="eggplantText">plucked it from the sky</span>...
          </p>

          <p>
          	“Who should we invite?” August asked, handing the glass to Iris.
          </p>

          <p>
          	As August listed friends, the cogs in Iris's mind churned in a
          	desperate attempt to pair faces with these ethereal phonemes, these
          	names of people Iris hadn't thought of in years, people she knew of
            but didn't know.
          </p>

          <p>
            “Do you still keep in touch with Lydia, or... what was his name,
            that eccentric poet...?”
          </p>

          <p>
          	“I do still speak with Lydia, but she's in Boston, so she won't be
          	able to attend. Eccentric poet? I'm not sure who that is...” Iris
          	replied, her voice fading as she tried to conjure a mental image of
          	this poet of whom August spoke.
          </p>

          <p>
            “You know, he's sort of... quirky, I remember he did interpretative
          	dance, spoken word... at recess he was always at the top of a tree...”
          </p>

          <p>
            “Oh, Rory!” Iris exclaimed, &amp; August quickly agreed, “yes yes,
            Rory.”
          </p>

          <p>
            “I haven't heard from him in several months, but in a lot of ways
          	that's typical of our friendship since graduation, the time elapses
          	but it doesn't feel like much has changed...”
          </p>

          <p>
            “I know the type of friendship,” August replied.
          </p>

          <p>
          	“I desperately need to give him a call... though I'm not sure he would
            make an appearance at the party, I don't think he drinks.”
          </p>

          <p>
          	“See what he says. Anyway, I'm sure our close friends are guaranteed,
          	but what about some eye-candy?” August inquired, winking so as to
          	highlight the deviousness of her question.
          </p>

          <p>
          	“Like who?” Iris shot back quickly, feeling a blush grow on her cheeks.
          </p>

          <p>
          	“I was thinking some of the guys who used to play on the soccer
            team... that sort of crowd, I think they know how to have fun.”
          </p>

          <p>
          		August continued on about that scene, reciting the news of their
          		cliques. She presented Iris with a sea of words, expecting no
              response (an occasional head-nod would suffice; a “yea, yea, yup”
              dotted throughout, like a calm seagull bobbing on the crest of a
              wave), &amp; Iris ceased to hear August's voice, instead finding
              herself thinking of parties &amp; what they were like now in
              contrast to the past. Saturday nights were a celebration for
              Saturn, she supposed, revelry to commemorate having made it
              through the week, <span className="mintText">temporary
              solace</span> from cubicles &amp; cash registers, &amp; in
          		<span className="roseText"> another lifetime</span>, years &amp;
              years ago, parties had been for birthdays, for celebrating growth
          		rather than to serve as distractions from their stagnation, their
          		discontent....
          	</p>
        </h3>

			<div className="centered">

				<Link to="/chapter4">
					<img
					className="arrow"
					src={process.env.PUBLIC_URL + "../icons/button_L.gif"}
					alt="left arrow"
					/>
				</Link>

				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        <Link to="/chapter4c">
          <img
            className="arrow"
            src={process.env.PUBLIC_URL + "../icons/button_R.gif"}
            alt="right arrow"
          />
        </Link>

				</div>

				<div className="pgNum">
					8
				</div>

			</div>
      </div>


	)};
};

export default Chapter4b;

import React, { Component } from "react";
import { Link } from 'react-router-dom';

import Chapter1Header from './Chapter1Header';

class Chapter1c extends Component {

	constructor() {
			super();
			this.state = {
				width: window.innerWidth,
			};
		}

		componentWillMount() {
			window.addEventListener('resize', this.handleWindowSizeChange);
		}

		// make sure to remove the listener
		// when the component is not mounted anymore
		componentWillUnmount() {
			window.removeEventListener('resize', this.handleWindowSizeChange);
		}

		handleWindowSizeChange = () => {
			this.setState({ width: window.innerWidth });
		};


render() {

	return (

		<div>
      <Chapter1Header/>

			<div className="chapterBody">

        <div className="pgNum">
					3
				</div>

        <h3>

					<p>
		        Iris walked towards the girl, averting her eyes when she thought the
		        brunette might see her, drawn to her by some <span className="roseText">
						unseen magnetic force that was beyond her control or comprehension</span>.
		        Perhaps the other was a vagabond, pausing her cross-country journey in
		  			this small town simply for a brief respite from the exhaustive
						progression of her travels. She wanted to rest her weary legs, for
						all travelers have weary legs, whether from the sole-numbing, trodden
						exploits of walking, or the thigh-trying fatigue of biking, or the
						blood-clot cramping of sitting with one's arms wrapped around the
						knees in the back of a pick-up truck. A change of pace was necessary.
						She might have begun her journey at the eastern seaboard, maybe with
						the eventual intent of tracing old Route 66, but one of the
						thumbs-up-signaled transports had been headed further north than
						she'd have liked, &amp; she finally asked him to let her out beside
						a town-line marker announcing Great Lespen, Minnesota; Lake Rouge,
						Iowa; or Candlefield, Wisconsin; &amp; she'd somehow made her way
						here. Iris thought this as she absent-mindedly traced her
		        fingers across the edge of a vendor's table, watching the girl ahead
		        linger under a tent of hanging plants. She probably abandoned a
		        boyfriend at home, maybe a pet bunny too, because the tug of the
		        Pacific had been too strong to resist, the salt-choked voice of the
		        crashing waves had called out to her, weakening somewhat in the arid
		        plains of the heartland &amp; again in the frigid hillsides of the
						Rockies but nevertheless it had reached her eager ear, &amp; -
					</p>

					<p>
		      	"<span className="eggplantText">Lavender tea</span>?"
					</p>

					<p>
		        Iris was dragged from her reverie by an elderly woman behind the table
		        at which she had paused, sun-drenched &amp; wrinkled, but pleasant-faced,
		        a glint in her eyes. Attempting to return a smile, though hers was
		        quick &amp; forced, Iris turned back to the crowds, intent on locating
		        the source of her intrigue once more. She took several steps from the
		        old woman's tent so as not to be ensnared in the back-and-forth of
		        haggling, but she could no longer find the girl - she had been born of
		        the crowd, &amp; henceforth must have returned to its masses of
						<span className="lilacText"> color &amp; warmth &amp; heartbeat</span>.
					</p>

					<p>
		        This was how the fantasy usually resolved itself, the center of this
		        universe was again swallowed up by the other ordinary bodies, or
		        slipped into the arms of some impenetrable edifice, like a home or
		        workplace. Other times, the demanding face of Iris's timepiece became
		        too difficult to ignore any longer, &amp; she was forced to return to
		        wherever she was supposed to be, to do whatever it was that she
		        ought to be doing. Occasionally the stranger did something
		        reprehensible, like pick his nose, or shamelessly bat her eyelashes
		        at some man, &amp; then Iris abandoned the tryst with the bile of
		        disgust bubbling in her stomach. These were the worst conclusions,
		        she thought, (craning her neck in a final attempt to see the girl one
		        last time before she left forever), because the stranger had defied her
		        one requirement, that he or she mustn't break the illusion -
					</p>

			 		<p>
		        "<span className="mintText">Looking for someone</span>?" a voice
						behind Iris asked.
	        </p>

					<p>
		        Iris whirled around &amp; found herself staring into the honey-colored
		        eyes of the girl of the crowd. The corners of her mouth turned up ever
		        so slightly, so that she seemed to smirk at Iris - perhaps she was
		        amused by Iris's wide-eyed expression &amp; utter inability to respond.
		        "Did we go to school together?" the girl continued, one eyebrow raised
		        in expectation. The breeze rustled her brown hair, a piece catching on
		        her lip. The question facilitated Iris's memory, &amp; slowly, the
		        slender nose, the large golden eyes, the sharp cheekbones seemed
		        familiar... but the hair seemed different, wrong.
					</p>

					<p>
		        "Maybe," Iris offered, reminding the girl of her name.
					</p>

					<p>
		        "Of course - Iris." She coolly revealed herself to be August, &amp;
						suddenly Iris remembered her childhood friend again, before they
						drifted apart in junior high when August's hair was short &amp;
						sheared, the tips dyed a different color every other month. Iris was
						taken aback at how much the years had changed her. They exchanged
						superficial updates as to the progression of their lives thus far:
						August was an art student these days, &amp; she too was home on break
						(her hypothetical footsteps on the asphalt of New York City, the
						sodden soil in Colorado, the shifting sand in California - they
						faded &amp; disappeared, <span className="goldText"> a universe
						darkened &amp; died</span>). August asked if Iris was still dating
						Ezra as they had during high school; Iris said no, they weren’t.
						Was August still dating... what was his name? No, she laughed,
						absolutely not. August gave Iris a lasting smile, taking one more
						moment to allow her eyes to linger on the other girl's face.
						"Hopefully I'll see you around," August concluded casually, turning
						on her heel, <span className="redText">&amp;
		        she was gone</span>.
					</p>

					<p>
						Iris purchased the sourdough &amp; spinach for which she'd ventured to
						the farmer's market, reviewing how greatly her life had changed since
						the time when August had known her, their amicable connection
						during elementary &amp; middle school fragmenting as the years had
						passed. Now, Iris had just one more year to complete before she
						would obtain her liberal arts degree (Comparative Literature &amp;
						Cognitive Psychology) &amp; could pursue writing professionally -
						but she'd always remained a dreamer, a fantasist.
					</p>

					<p>
						Yes, things were so very different now, she thought, the farmer's
						market shrinking in the distance as she pedaled away to return home,
						her attention now dipping into the past, as though a wooden bucket
						descending a calm, quiet well.
					</p>

					<p>
						Ezra had been a philosopher, an intellectual, &amp; a theologian,
						&amp; he was tortured by the way in which these disciplines were often
						&amp; irresolutely in opposition with each other. He embodied their strife
						in his own psychic pain, which was evident at times in his mannerisms
						(extreme politeness &amp; propriety, a slight social awkwardness that
						was nevertheless endearing), &amp; frequently in his eyes (tormented,
						they had been, Iris still remembered). Of course, Iris did not know
						all of this about him at first; despite attending the same small-town
						high school, she'd heard of him, but had never yet met him. Forever
						an academic overachiever, during her sophomore year she further
						stacked her course load with a chemistry class for juniors, &amp;
						among this unfamiliar group of people, the teacher had them arrange
						themselves into the lines of desks based on birthday order. January
						sat in front of February, March began the next column, &amp; so on.
						The teacher told the students to look to the person seated next to
						them - so Iris turned, facing the green-eyed boy to her left - these
						were to be the lab partner pairings. And thus, Iris
						met Ezra, (whose name she had heard echo in the hallways), in a
						<span className="mauveText"> bizarre convergence of
						fate &amp; stringent seating policy</span>.
					</p>

        </h3>

				<div className="centered">
          <Link to='/chapter1b'>
            <img
              className="arrow"
              src={process.env.PUBLIC_URL + "../icons/button_L.gif"}
              alt="left arrow"
              onClick={this.handleClickBack}
            />
          </Link>

          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

          <Link to="/">
            <img
              className="arrow"
              src={process.env.PUBLIC_URL + "../icons/home_green.gif"}
              alt="home icon"
            />
        	</Link>

				</div>

				<div className="pgNum">
					3
				</div>

			</div>
		</div>

	)};
};


export default Chapter1c;
